import React, { useContext, useEffect, useState } from 'react'
import {  Routes, Route, useNavigate } from 'react-router-dom'
import axios from 'axios'
import qs from 'qs'
import {
  PageHeader,
  Table
} from 'antd'
import * as dayjs from 'dayjs'

import EditEstimate from './edit'
import ViewEstimate from './view'

import { convertStringToTitleCase } from '../../helpers'
import { userContext } from '../../context/User.js'

const ViewEstimatesTable = () => {
  const navigate = useNavigate()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [tableParams, setTableParams] = useState({
    field: 'modifiedDate',
    order: 'descend',
    pagination: {
      current: 1,
      pageSize: 20,
    },
    filters: {
      type: [
        'building'
      ],
      status: [
        'draft',
        'pending',
        'approved',
        'ordered'
      ]
    }
  })
  const user = useContext(userContext)
  const canManage = user.permissions.includes('manage-estimates')

  let columns = [
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      sorter: true,
      ellipsis: true,
      width: '12.5%',
      render: (text, record) => dayjs(text).format('M/D/YY h:mma')
    },
    {
      title: 'Last Modified',
      dataIndex: 'modifiedDate',
      defaultSortOrder: 'descend',
      sorter: true,
      ellipsis: true,
      width: '12.5%',
      render: (text, record) => dayjs(text).format('M/D/YY h:mma')
    },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: true,
      ellipsis: true,
      width: '30%'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: true,
      ellipsis: true,
      width: '10%',
      filters: [
        {
          text: 'Building',
          value: 'building',
        },
        {
          text: 'Storage',
          value: 'storage',
        }
      ],
      filteredValue: tableParams.filters.type,
      render: (text, record) => convertStringToTitleCase(text)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      ellipsis: true,
      width: '10%',
      filters: [
        {
          text: 'Draft',
          value: 'draft',
        },
        {
          text: 'Pending',
          value: 'pending',
        },
        {
          text: 'Approved',
          value: 'approved',
        },
        {
          text: 'Ordered',
          value: 'ordered',
        },
        {
          text: 'Complete',
          value: 'complete',
        }
      ],
      filteredValue: tableParams.filters.status,
      render: (text, record) => convertStringToTitleCase(text)
    }
  ]

  if (canManage) {
    columns.splice(3, 0, {
      title: 'Creator',
      dataIndex: 'creator',
      sorter: true,
      ellipsis: true,
      width: '12.5%',
      render: (text, record) => record.creator ? (record.creator.first_name + ' ' + record.creator.last_name) : '(User Not Found)'
    })

    columns.splice(4, 0, {
      title: 'Dealer',
      dataIndex: 'dealer',
      sorter: true,
      ellipsis: true,
      width: '12.5%',
      render: (text, record) => record.dealer ? (record.dealer.first_name + ' ' + record.dealer.last_name) : '(User Not Found)'
    })
  }

  const fetchData = () => {
    setLoading(true)

    let params = {
      page: tableParams.pagination.current,
      limit: tableParams.pagination.pageSize,
      order: tableParams.order === 'ascend' ? 'asc' : 'desc',
      orderby: tableParams.field ?? 'modifiedDate'
    }

    for (const filterKey in tableParams.filters) {
      if (tableParams.filters[filterKey]) {
        params[filterKey] = tableParams.filters[filterKey]
      }
    }

    const urlParams = qs.stringify(params)

    axios.get(`/api/estimates?${ urlParams }`, {
      withCredentials: true
    }).then(res => {
      setData(res.data)
      setLoading(false)
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: parseInt(res.headers['available-items'])
        },
      })
    })
  }

  useEffect(() => {
    fetchData();
  }, [ JSON.stringify(tableParams) ]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    })
  }

  return (
    <>
      <PageHeader title="Estimates" />

      <div id="parts-materials">
        <Table
          columns={ columns }
          rowKey="id"
          dataSource={ data }
          pagination={ tableParams.pagination }
          filters={ tableParams.filters }
          loading={ loading }
          onChange={ handleTableChange }
          onRow={ record => {
            return {
              onClick: (e) => navigate('/estimates/' + record.id)
            }
          } }
          rowClassName="pointer-cursor"
          size="small"
        />
      </div>
    </>
  )
}

export default function ViewEstimates () {
  return (
    <div className="estimate">
      <Routes>
        <Route path='/' element={ <ViewEstimatesTable /> } />
        <Route path="/:id" element={ <ViewEstimate /> } />
        <Route path="/:id/edit" element={ <EditEstimate /> } />
      </Routes>
    </div>
  )
}