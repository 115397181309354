import { renderToStaticMarkup } from 'react-dom/server'
import Fuse from 'fuse.js'
import NumberFormat from 'react-number-format'

export const convertStringToTitleCase = (str) => str.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
export const convertDashCaseToTitleCase = (str) => str.toLowerCase().split('-').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
export const convertCamelCaseToTitleCase = (str) => str.split(/(?=[A-Z])/).join(' ')

export const cloneObjectExcludingKeys = (obj, keys) => {
  let newObj = structuredClone(obj)
  keys.forEach(key => delete newObj[key])
  return newObj
}

export const generateTableColumns = (columns, capitalizeHeaders, customRender, customCellStyle) => {
  const results = columns.map((key) => {
    if (typeof key === 'string') {
      return {
        key,
        title: capitalizeHeaders ? convertStringToTitleCase(convertCamelCaseToTitleCase(key)) : key,
        dataIndex: key,
        onCell: (item) => {
          return {
            style: customCellStyle ? customCellStyle(item[key], key, item) : null
          }
        },
        render: (value, item) => {
          return customRender ? customRender(value, key, item) : Array.isArray(value) ? value.join(', ') : value
        }
      }
    }
    
    return key
  })
  
  return results
}

export const handleSearch = (search, data, setData, fields) => {
  if (!search || !search.trim().length) {
    setData(data)
    return data
  }

  const fuse = new Fuse(data, { keys: fields ?? Object.keys(data[0]) })
  const results = fuse.search(search).map(i => i.item)

  setData(results)
  return results
}

export const numberFormat = props => renderToStaticMarkup(
  <NumberFormat { ...props } displayType="text" renderText={ value => value.toString() } />
)