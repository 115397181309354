import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import {
  Button,
  Col,
  message,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
} from 'antd'
import {
  DeleteOutlined,
  LockOutlined
} from '@ant-design/icons'

import { userContext } from '../../context/User.js'

const { Option } = Select

export default function EditUser () {
  const user = useContext(userContext)
  const params = useParams()
  const userId = params.userId ?? user.id
  const isMyProfile = user.id === userId
  const canManageUsers = user.permissions.includes('manage-users')
  const [ loading, setLoading ] = useState(false)
  const [ userData, setUserData ] = useState({})
  const [ userRoles, setUserRoles ] = useState({})
  const [ form ] = Form.useForm()
  const location = useLocation()
  const navigate = useNavigate()

  const deleteUser = () => {
    if (!window.confirm('Are you sure you wish to delete this user? This cannot be undone!')) return

    setLoading(true)

    axios.delete(`/api/user/${userId}`, {
      withCredentials: true
    }).then(res => {
      setLoading(false)
      navigate('/users')
    })
  }

  const fetchRoles = () => {
    setLoading(true)

    axios.get(`/api/user-roles`, {
      withCredentials: true
    }).then(res => {
      setUserRoles(res.data)
      setLoading(false)
    })
  }

  const fetchUserData = () => {
    setLoading(true)

    axios.get(`/api/user/${userId}`, {
      withCredentials: true
    }).then(res => {
      setUserData(res.data)
      form.setFieldsValue(res.data)
      setLoading(false)
    })
  }

  const saveChanges = (values) => {
    axios.post(`/api/user/${userId}`, {
      ...values
    }, {
      withCredentials: true
    }).then(res => {
      message.success('Profile Updated')
    }, () => {
      message.error('There was an issue with your request.')
    })
  }

  useEffect(() => {
    fetchUserData()

    if (!isMyProfile && canManageUsers) {
      fetchRoles()
    }
  }, [ location ])

  return ( (loading || !userData) ? <p>Loading...</p> :
    <>
      <div id="edit-user">
        <PageHeader
          className="resources-page-header"
          title={ isMyProfile ? 'Edit Profile' : `Edit User ${userData.username}` }
          onBack={ () => navigate('/users') }
        />

        <Form form={ form } layout="vertical" name="editUser" onFinish={ saveChanges }>
          <Form.Item
          name="first_name"
          label="First Name"
          rules={[
            {
              required: true,
              message: 'First name is required.',
            }
          ]}
          >
            <Input style={{ maxWidth: 350 }} />
          </Form.Item>

          <Form.Item
          name="last_name"
          label="Last Name"
          rules={[
            {
              required: true,
              message: 'Last name is required.',
            }
          ]}
          >
            <Input style={{ maxWidth: 350 }} />
          </Form.Item>

          <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Email is required.',
            }
          ]}
          >
            <Input style={{ maxWidth: 350 }} />
          </Form.Item>

          <Form.Item name="phone_number" label="Phone Number">
            <Input style={{ maxWidth: 350 }} />
          </Form.Item>

          <Form.Item
          name="company"
          label="Company"
          rules={[
            {
              required: true,
              message: 'Company name is required.',
            }
          ]}
          >
            <Input style={{ maxWidth: 350 }} />
          </Form.Item>

          <Form.Item name="street_address" label="Street Address">
            <Input style={{ maxWidth: 350 }} />
          </Form.Item>

          <Form.Item name="city" label="City">
            <Input style={{ maxWidth: 350 }} />
          </Form.Item>

          <Form.Item name="state" label="State">
            <Input style={{ maxWidth: 350 }} />
          </Form.Item>

          <Form.Item name="zip_code" label="Zip Code">
            <Input style={{ maxWidth: 350 }} />
          </Form.Item>

          { (canManageUsers && Object.keys(userRoles).length) ?
            <Form.Item
              name="roles"
              label="User Roles"
              rules={[
                {
                  required: true,
                  message: 'User role is required.',
                }
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%', maxWidth: 350 }}
                placeholder="Please select"
              >
                { Object.keys(userRoles).map(roleKey => <Option key={ roleKey } value={ roleKey }>{ userRoles[roleKey].name }</Option>) }
              </Select>
            </Form.Item>
          : null }

          <Row gutter={24}>
            <Col>
              <Button onClick={ () => navigate('/users') }>Cancel</Button>
            </Col>

            <Col>
              <Button type="primary" htmlType="submit">Save Changes</Button>
            </Col>

            <Col>
              <Button onClick={ () => navigate(`/users/${userId}/password`) } icon={ <LockOutlined /> }>Change Password</Button>
            </Col>

            { canManageUsers &&
              <Col>
                <Button type="primary" danger={ true } onClick={ deleteUser } icon={ <DeleteOutlined /> }>Delete User</Button>
              </Col>
            }
          </Row>
        </Form>
      </div>
    </>
  )
}
