import React, { useState } from 'react'
import {
  Input,
  Table,
  Button,
  PageHeader
} from 'antd'

const SaltStorageCalcTable = () => {
  const [inputValues, setInputValues] = useState({})
  const [calculationResults, setCalculationResults] = useState({})

  const handleCalculate = () => {
    const width = parseFloat(inputValues['width'])
    const length = parseFloat(inputValues['length'])
    const saltHeight = parseFloat(inputValues['saltHeight'])
    const saltWeight = parseFloat(inputValues['saltWeight'])
    const minHeight = saltHeight + ((width / 2) * 0.65)
    const squareFoot = width * length
    const saltVolume = 0.162 * Math.pow(width, 2) * length - 0.052 * Math.pow(width, 3) - 0.25 * saltHeight * Math.pow(width, 2) - 0.77 * Math.pow(saltHeight, 2) * width + width * length * saltHeight
    const saltCapacity = saltVolume * saltWeight
    const capacity = saltCapacity / 2000

    setCalculationResults((prevResults) => ({
      ...prevResults,
      capacity: capacity,
      minHeight: minHeight,
      squareFoot: squareFoot,
      saltCapacity: saltCapacity,
      saltVolume: saltVolume,
    }))
  }

  const handleInputChange = (key, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [key]: value,
    }))
  }

  const columns = [
    {
      title: 'Input',
      dataIndex: 'fieldTitle',
      render: (_, record) => <span>{record.fieldTitle}</span>,
    },
    {
      dataIndex: 'input',
      render: (_, record) => (
        <Input placeholder="Enter value" onChange={(e) => handleInputChange(record.key, e.target.value)} />
      ),
    },
  ]

  const data = [
    { key: 'width', fieldTitle: 'Width of Building (ft):' },
    { key: 'length', fieldTitle: 'Length of Building (ft):' },
    { key: 'saltHeight', fieldTitle: 'Salt height at side wall (ft):' },
    { key: 'saltWeight', fieldTitle: 'Weight of Salt (lbs per Cubic ft) *Use 72 lbs/Cu. ft. for salt and fertilizer' },
  ]

  return (
    <div style={{ textAlign: 'center' }}>
      <Table columns={columns} dataSource={data} pagination={false} />
      <Button type="primary" style={{ margin: 15 }} onClick={handleCalculate}>Calculate</Button>
      <SaltStorageResultTable calculationResults={calculationResults} />
    </div>
  )
}

const SaltStorageResultTable = ({ calculationResults }) => {
  const columns = [
    {
      title: 'Output',
      dataIndex: 'fieldTitle',
      render: (_, record) => <span>{record.fieldTitle}</span>,
    },
    {
      dataIndex: 'input',
      render: (_, record) => (
        <>
          {record.key in calculationResults && !isNaN(calculationResults[record.key]) ? calculationResults[record.key].toLocaleString() : record.initialValue}
        </>
      ),
    },
  ]

  const data = [
    { key: 'capacity', fieldTitle: 'Capacity (tons):', initialValue: 0 },
    { key: 'minHeight', fieldTitle: 'Min total height of shed on Wall (ft):', initialValue: 0 },
    { key: 'squareFoot', fieldTitle: 'Building Square Footage', initialValue: 0 },
    { key: 'saltCapacity', fieldTitle: 'Salt Capacity (lbs):', initialValue: 0 },
    { key: 'saltVolume', fieldTitle: 'Salt Volume (ft^3):', initialValue: 0 },
  ]

  return <Table columns={columns} dataSource={data} pagination={false} />
}


export default function StorageCalculator() {
  return (
    <div>
      <PageHeader title="Salt Storage Calculator" />
      <SaltStorageCalcTable />
    </div>
  )
}