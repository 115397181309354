import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Radio,
  Row,
  Select,
  Upload,
} from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  UploadOutlined
} from '@ant-design/icons'

import FileTypeIcon from '../../components/FileTypeIcon'

import { userContext } from '../../context/User.js'

export default function Resources () {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false)
  const [isEditResourceModalOpen, setIsEditResourceModalOpen] = useState(false)
  const [data, setData] = useState()
  const [categories, setCategories] = useState([])
  const [resources, setResources] = useState([])
  const [editingCategory, setEditingCategory] = useState({})
  const [editingResource, setEditingResource] = useState({})
  const [loading, setLoading] = useState(false)
  const [editCategoryForm] = Form.useForm()
  const [editResourceForm] = Form.useForm()
  const editingResourceType = Form.useWatch('type', editResourceForm)
  const user = useContext(userContext)
  const canEdit = user.permissions.includes('edit-resources')

  const deleteCategory = categoryId => {
    setConfirmLoading(true)

    axios.delete(`/api/categories/${categoryId}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          message.success(`Resource category successfully deleted.`)
          fetchData()
        } else {
          message.error(`An error occured while attempting to delete your resource category.`)
        }

        setConfirmLoading(false)
      })
      .catch(err => {
        console.log(err)
        message.error(`An error occured while attempting to delete your resource category.`)
        setConfirmLoading(false)
      })
  }

  const deleteResource = resourceId => {
    setConfirmLoading(true)

    axios.delete(`/api/resources/${resourceId}`, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          message.success(`Resource successfully deleted.`)
          fetchData()
        } else {
          message.error(`An error occured while attempting to delete your resource.`)
        }

        setConfirmLoading(false)
      })
      .catch(err => {
        console.log(err)
        message.error(`An error occured while attempting to delete your resource.`)
        setConfirmLoading(false)
      })
  }

  const editCategory = e => {
    setConfirmLoading(true)
    
    const editData = editCategoryForm.getFieldsValue(true)
    editData.type = 'resource'

    const isUpdate = editingCategory.id

    if (isUpdate) {
      editData.id = editingCategory.id
    }

    editCategoryForm.validateFields()
      .then(values => axios.post('/api/categories', {
        ...editData
      }, {
        withCredentials: true
      }).then(res => {
        editCategoryForm.resetFields()
        setEditingCategory({})
        setIsEditCategoryModalOpen(false)
        fetchData()
        setConfirmLoading(false)
        message.success(`Resource category ${editData.title} successfully ${isUpdate ? 'updated' : 'created'}!`)
      }, (res) => {
        console.error('Somethings not right...', res.message)
        message.error(`An error occured while attempting to ${isUpdate ? 'update' : 'create'} your resource category.`)
        setConfirmLoading(false)
      })
    )
    .catch(err => {
      console.error(err)
      message.error('There were issues with the information you provided.')
      setConfirmLoading(false)
    })
  }
  
  const editResource = e => {
    setConfirmLoading(true)

    const formData = new FormData()
    const editData = editResourceForm.getFieldsValue(true)

    const isUpdate = editingResource.id

    if (isUpdate) {
      editData.id = editingResource.id
    }

    if (editingResourceType === 'file') {
      delete editData.url
      delete editData.filePath
    }

    for (const dataKey in editData) {
      if (dataKey === 'file') {
        editData.file = editData.file.file
      }

      formData.append(dataKey, editData[dataKey])
    }

    editResourceForm.validateFields()
      .then(values => axios.post('/api/resources', formData, {
        withCredentials: true
      }).then(res => {
        editResourceForm.resetFields()
        setEditingResource({})
        setIsEditResourceModalOpen(false)
        fetchData()
        setConfirmLoading(false)
        message.success(`Resource ${editData.title} successfully ${isUpdate ? 'updated' : 'created'}!`)
      }, (res) => {
        console.error('Somethings not right...', res.message)
        message.error(`An error occured while attempting to ${isUpdate ? 'update' : 'create'} your resource.`)
        setConfirmLoading(false)
      })
    )
    .catch(err => {
      console.error(err)
      message.error('There were issues with the information you provided.')
      setConfirmLoading(false)
    })

    setConfirmLoading(false)
  }

  const formatData = (resources, categories) => {
    let formattedData = {}
    
    categories.forEach(category => {
      formattedData[category.slug] = {
        id: category.id,
        title: category.title,
        order: category.order,
        resources: []
      }
    })

    resources
      .sort((a, b) => a.order - b.order)
      .forEach((resource) => {
        if (resource.categoryId) {
          const category = categories.find(c => c.id === resource.categoryId)

          if (category) {
            formattedData[category.slug].resources.push(resource)
          } else {
            formattedData.uncategorized.resources.push(resource)
          }
        } else {
          formattedData.uncategorized.resources.push(resource)
        }
      })
    
    if (!formattedData.uncategorized.resources.length) {
      delete formattedData.uncategorized
    }

    return formattedData
  }
  
  const fetchData = () => {
    setLoading(true)

    axios.all([
      axios.get(`/api/resources`, {
        withCredentials: true
      }),
      axios.get(`/api/categories`, {
        withCredentials: true
      })
    ]).then(res => {
      const theResources = res[0].data
      let theCategories = res[1].data

      theCategories.push({
        id: null,
        title: 'Uncategorized',
        description: null,
        slug: 'uncategorized',
        order: 100
      })

      const formattedData = formatData(theResources, theCategories)

      setResources(theResources)
      setCategories(theCategories)
      setData(formattedData)
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (isEditCategoryModalOpen && editCategoryForm && editCategoryForm.setFieldsValue) {
      const editCategoryFormData = editingCategory
      editCategoryForm.setFieldsValue(editCategoryFormData)
    }
  }, [ editingCategory ])
  
  useEffect(() => {
    if (isEditResourceModalOpen && editResourceForm && editResourceForm.setFieldsValue) {
      let editResourceFormData = editingResource
      editResourceFormData.type = editResourceFormData.filePath ? 'file' : 'link'
      editResourceForm.setFieldsValue(editResourceFormData)
    }
  }, [ editingResource ])

  return ( (loading || !data) ? <p>Loading...</p> :
    <div id="resources">
      <PageHeader
        className="resources-page-header"
        title="Resources"
        extra={[
          canEdit &&
          <Button
            key="create-category"
            onClick={ e => {
              setEditingCategory({})
              setIsEditCategoryModalOpen(true) 
            }}>Create New Resource Category</Button>
        ]}
      />

      <Row gutter={[ { xs: 8, sm: 16, md: 24, lg: 32 }, { xs: 8, sm: 16, md: 24, lg: 32 } ]}>
        { Object.keys(data)
            .sort((a, b) => data[a].order - data[b].order)
            .map(categorySlug => {
              const category = data[categorySlug]

              return (
                <Col key={ `${categorySlug}-column` } xs={ 24 } sm={ 24 } md={ 12 } lg={ 8 }>
                  <div className="single-resource">
                    <List
                      header={
                        <>
                          <div className="resource-category-title">{ category.title }</div>

                          { canEdit && category.id &&
                            <div className="resource-category-title-controls">
                              <EditOutlined onClick={ e => {
                                setEditingCategory(category)
                                setIsEditCategoryModalOpen(true) 
                              }} />

                              <Popconfirm onConfirm={ e => deleteCategory(category.id) } title="Are you sure？" okText="Yes" cancelText="No">
                                <DeleteOutlined />
                              </Popconfirm>
                            </div>
                          }
                        </>
                      }
                      itemLayout="horizontal"
                      dataSource={ category.resources }
                      renderItem={ resource => (
                        <List.Item
                          key={ resource.id }
                          actions={[
                            canEdit &&
                            <Button
                              onClick={ e => {
                                setEditingResource(resource)
                                setIsEditResourceModalOpen(true)
                              } }
                              icon={ <EditOutlined/> }
                            />,
                            canEdit &&
                            <Popconfirm onConfirm={ e => deleteResource(resource.id) } title="Are you sure？" okText="Yes" cancelText="No">
                              <Button icon={ <DeleteOutlined /> } danger></Button>
                            </Popconfirm>
                          ]}
                        >
                          <List.Item.Meta
                            avatar={ <FileTypeIcon file={ resource.url } /> }
                            title={ <a href={ resource.url }>{ resource.title }</a> }
                            description={ resource.description }
                          />
                        </List.Item>
                      ) }
                    />

                    { canEdit &&
                      <Button
                        type="primary"
                        size="large"
                        onClick={ e => {
                          setEditingResource({ categoryId: category.id })
                          setIsEditResourceModalOpen(true)
                        } }
                        block>+ Add Resource</Button>
                    }
                  </div>
                </Col>
              )
            })
        }
      </Row>
      
      <Modal
        title={ editingCategory.id ? 'Edit Resource Category' : 'Create New Resource Category' }
        open={ isEditCategoryModalOpen }
        onOk={ e => editCategory() }
        onCancel={ e => {
          editCategoryForm.resetFields()
          setEditingCategory({})
          setIsEditCategoryModalOpen(false)
        } }
        confirmLoading={ confirmLoading }
        okText="Save Changes"
        forceRender={ true }
        getContainer={ false }
      >
        <Form form={ editCategoryForm } initialValues={{ order: 10 }}>
          <Form.Item name="title" label="Category Title" rules={[ { required: true } ]}>
            <Input />
          </Form.Item>

          <Form.Item
          name="order"
          label="Order"
          tooltip="A resource category with a lower number will appear before resource categories with a higher number."
          rules={[ { required: true } ]}
          >
            <InputNumber min={1} max={99} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={ editingResource.id ? 'Edit Resource' : 'Create New Resource' }
        open={ isEditResourceModalOpen }
        onOk={ e => editResource() }
        onCancel={ e => {
          editResourceForm.resetFields()
          setEditingResource({})
          setIsEditResourceModalOpen(false)
        } }
        confirmLoading={ confirmLoading }
        okText="Save Changes"
        forceRender={ true }
        getContainer={ false }
      >
        <Form form={ editResourceForm } initialValues={{ order: 10, type: 'link' }}>
          <Form.Item name="title" label="Title" rules={[ { required: true } ]}>
            <Input />
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input />
          </Form.Item>

          <Form.Item name="categoryId" label="Category">
            <Select options={ categories.sort((a, b) => a.order - b.order).map(c => Object({ value: c.id ?? '', label: c.title })) } />
          </Form.Item>

          <Form.Item
          name="order"
          label="Order"
          tooltip="A resource with a lower number will appear before resources with a higher number."
          rules={[ { required: true } ]}
          >
            <InputNumber min={1} max={99} />
          </Form.Item>

          <Form.Item name="type" label="Type" rules={[ { required: true } ]}>
            <Radio.Group>
              <Radio value="link">Link</Radio>
              <Radio value="file">File</Radio>
            </Radio.Group>
          </Form.Item>

          { editingResourceType === 'link' &&
            <Form.Item name="url" label="URL" rules={[ { required: true } ]}>
              <Input />
            </Form.Item>
          }

          { editingResourceType === 'file' &&
            <>
              { editingResource.filePath &&
                <Form.Item label="File">
                  <a href={ editingResource.url }>{ editingResource.filePath.substring(editingResource.filePath.lastIndexOf('/') + 1) }</a>
                </Form.Item>
              }

              <Form.Item
              name="file"
              label={ editingResource.filePath ? 'Replace File' : 'File' }
              valuePropName="file"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!editingResource.filePath && !value) {
                      return Promise.reject(new Error("'file' is required"))
                    }
              
                    return Promise.resolve()
                  }
                })
              ]}
              >
                <Upload maxCount={1} beforeUpload={ file => false }>
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
              </Form.Item>
            </>
          }
        </Form>
      </Modal>
    </div>
  )
}