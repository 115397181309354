import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import {
  Button,
  Col,
  Row
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  PrinterOutlined,
  SaveOutlined
} from '@ant-design/icons'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'

import ViewBuildingEstimate from './building'

import { userContext } from '../../context/User.js'

export default function ViewEstimate () {
  const [ data, setData ] = useState()
  const [ loading, setLoading ] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()
  const componentRef = useRef()
  const printRef = useRef()
  const user = useContext(userContext)
  const canManage = user.permissions.includes('manage-estimates')
  const canDelete = user.permissions.includes('delete-estimates')

  const deleteEstimate = () => {
    if (!window.confirm('Are you sure you wish to delete this estimate? This cannot be undone!')) return

    setLoading(true)

    axios.delete(`/api/estimates/${ id }`, {
      withCredentials: true
    }).then(res => {
      setLoading(false)
      navigate('/estimates')
    }).catch(err => {
      setLoading(false)
    })
  }

  const fetchData = () => {
    setLoading(true)

    axios.get(`/api/estimates/${ id }`, {
      withCredentials: true
    }).then(res => {
      setData(res.data)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
  }

  const ActionButtons = () => (
    <Row gutter={24}>
      <Col>
        <ReactToPrint content={ () => printRef.current }>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <Button onClick={ handlePrint } icon={ <PrinterOutlined /> }>Print</Button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>
      </Col>

      <Col>
        <Button onClick={ (e) => componentRef.current.onDownload() } icon={ <SaveOutlined /> }>Download</Button>
      </Col>

      { (canManage || data.status === 'draft') &&
        <Col>
          <Button onClick={ (e) => navigate(`/estimates/${ id }/edit`) } icon={ <EditOutlined /> }>Edit</Button>
        </Col>
      }

      { canDelete &&
        <Col>
          <Button type="primary" danger={ true } onClick={ deleteEstimate } icon={ <DeleteOutlined /> }>Delete Estimate</Button>
        </Col>
      }
    </Row>
  )

  const ViewEstimateComponent = forwardRef((childProps, ref) => {
    switch(childProps.data.type) {
      case 'building':
        return <ViewBuildingEstimate ref={ ref } {...childProps} />
      default:
        return <p>No view available for { childProps.data.type } estimate type.</p>
    }
  })

  useEffect(() => {
    fetchData()
  }, [])

  return (
    loading ? <p>Loading...</p> : !data ? <p>Could not find estimate</p> :
    (
      <>
        <div ref={ printRef }>
          <ViewEstimateComponent ref={ componentRef } data={ data } onBack={ () => navigate('/estimates') } />
        </div>
        <ActionButtons />
      </>
    )
  )
}