import React, { forwardRef, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { message } from 'antd'

import EditBuildingEstimate from './building/edit'

export default function EditEstimate () {
  const [ data, setData ] = useState()
  const [ loading, setLoading ] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()

  const updateEstimate = (values) => {
    axios.post(`/api/estimates/${ id }`, {
      ...values
    }, {
      withCredentials: true
    }).then((res) => {
      navigate('/estimates')
      message.success('Your estimate was successfully saved!')
    }, (res) => {
      console.error(res)
      message.error('An unknown error occured while saving your estimate.')
    })
  }

  const fetchData = () => {
    setLoading(true)

    axios.get(`/api/estimates/${ id }`, {
      withCredentials: true
    }).then(res => {
      setData(res.data)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
  }

  const EditEstimateComponent = forwardRef((childProps, ref) => {
    switch(childProps.data.type) {
      case 'building':
        return <EditBuildingEstimate {...childProps} />
      default:
        return <p>No view available for { childProps.data.type } estimate type.</p>
    }
  })

  useEffect(() => {
    fetchData()
  }, [])

  return (
    loading ? <p>Loading...</p> : !data ? <p>Could not find estimate</p> :
    (
      <>
        <EditEstimateComponent data={ data } onFinish={ updateEstimate } onCancel={ () => navigate('/estimates') } />
      </>
    )
  )
}