import { getParts, getPartByPartNumber } from '../parts'

import { manufacturedPartCalculations as advantageManufacturedPartCalculations } from './advantage'
import { manufacturedPartCalculations as integrityManufacturedPartCalculations } from './integrity'
import { manufacturedPartCalculations as integrityCattleManufacturedPartCalculations } from './integrity-cattle'

import advantageManufacturedParts from '../../data/advantage/manufactured-parts'
import integrityManufacturedParts from '../../data/integrity/manufactured-parts'
import integrityCattleManufacturedParts from '../../data/integrity/cattle/manufactured-parts'

import advantageQuantityDependencies from '../../data/advantage/quantity-dependencies'
import integrityQuantityDependencies from '../../data/integrity/quantity-dependencies'
import integrityCattleQuantityDependencies from '../../data/integrity/cattle/quantity-dependencies'

export const getManufacturedPartsDataset = (type) => {
  let manufacturedParts

  switch(type) {
    case 'integrity':
      manufacturedParts = integrityManufacturedParts
      break
    case 'integrity-cattle':
      manufacturedParts = integrityCattleManufacturedParts
      break
    case 'advantage':
    default:
      manufacturedParts = advantageManufacturedParts
  }

  return manufacturedParts
}

export const getManufacturedPartCalculations = (type) => {
  let manufacturedPartCalculations

  switch(type) {
    case 'integrity':
      manufacturedPartCalculations = integrityManufacturedPartCalculations
      break
    case 'integrity-cattle':
      manufacturedPartCalculations = integrityCattleManufacturedPartCalculations
      break
    case 'advantage':
    default:
      manufacturedPartCalculations = advantageManufacturedPartCalculations
  }

  return manufacturedPartCalculations
}

export const getManufacturedPartDependencies = (type) => {
  let manufacturedPartDependencies

  switch(type) {
    case 'integrity':
      manufacturedPartDependencies = integrityQuantityDependencies
      break
    case 'integrity-cattle':
      manufacturedPartDependencies = integrityCattleQuantityDependencies
      break
    case 'advantage':
    default:
      manufacturedPartDependencies = advantageQuantityDependencies
  }

  return manufacturedPartDependencies
}

export const getManufacturedParts = (values, type) => {
  const manufacturedParts = getManufacturedPartsDataset(type)
  const manufacturedPartCalculations = getManufacturedPartCalculations(type)
  const manufacturedPartDependencies = getManufacturedPartDependencies(type)

  const calculatePartQuantity = (partIdentifier, values, parts) => {
    const existingPart = parts.find(p => p.partNumber == partIdentifier || p.id == partIdentifier)

    if (!existingPart) {
      const quantityCalculation = manufacturedPartCalculations[partIdentifier]
      const quantityDependencies = manufacturedPartDependencies[partIdentifier]

      let part = manufacturedParts.find(p => p.partNumber == partIdentifier || p.id == partIdentifier) ?? {}

      if (part && quantityCalculation) {
        part = structuredClone(part)

        if (quantityDependencies) {
          quantityDependencies.forEach(depPartIdentifier => calculatePartQuantity(depPartIdentifier, values, parts))
        }

        part.requiredQty = quantityCalculation(values, parts.reduce((items, item) => {
          items[item.partNumber ?? item.id] = item.requiredQty ?? 0
          return items
        }, {})) ?? 0
      } else {
        part.requiredQty = 0
      }

      part.addQty = ((values.additions && values.additions[partIdentifier]) ? values.additions[partIdentifier] : 0)
      part.qty = (part.requiredQty + part.addQty)

      parts.push(part)
    }

    return parts
  }

  const partIdentifiers = manufacturedParts.map(p => p.partNumber ?? p.id)

  return partIdentifiers
    .reduce((parts, partIdentifier) => calculatePartQuantity(partIdentifier, values, parts), [])
    .filter(part => part.qty)
    .sort((a, b) => {
      const partA = manufacturedParts.find(p => p.id === a.id)
      const partB = manufacturedParts.find(p => p.id === b.id)
      return manufacturedParts.indexOf(partA) - manufacturedParts.indexOf(partB)
    })
    .map(p => {
      let part = {}

      if (p.partNumber) {
        part = getPartByPartNumber(p.partNumber, type, values)
      } else {
        const foundParts = getParts(p.id, type, values)

        if (foundParts && foundParts.length) {
          part = part[0]
        }
      }

      p.parts = part ? [part] : []
      p.weight = ((part?.weight ?? 0) * p.qty) || 0
      p.hours = (((part?.labor ?? 0) * p.qty) / 60) || 0
      p.cost = ((part?.totalMrk ?? 0) * p.qty) || 0

      return p
    })
}

export const calculateBelowZeroDistance = (belowZeroDistance) => {
  if(!belowZeroDistance) return 0
  
  let belowZeroArray = belowZeroDistance.split('-')
  let foot = parseInt(belowZeroArray[0])
  let inch = parseInt(belowZeroArray[1])/12
  return foot + inch
}

export const calculateLengthByRafterSpacing = (values, widths, multiplier) => {
  if (!Array.isArray(widths)) widths = [widths]
  if (widths.includes(values.buildingWidth)) {
    let math = ((parseInt(values.buildingLength) / parseInt(values.rafterSpacing) + 1) * multiplier)
    return math
  }
  return 0
}
