import React from 'react'
import {
  PageHeader,
  Tabs
} from 'antd'

import PartsAndMaterialsTable from '../components/PartsAndMaterialsTable.js'

export default function CreateEstimate () {
  return (
    <>
      <PageHeader title="Parts &amp; Materials" />
      <div id="parts-materials">
        <Tabs
          defaultActiveKey="advantage"
          size="small"
          items={[
            {
              key: 'advantage',
              label: 'Advantage',
              children: <Tabs
                defaultActiveKey="advantage-materials"
                size="small"
                items={[
                  {
                    key: 'advantage-materials',
                    label: 'Materials',
                    children: <PartsAndMaterialsTable dataType='materials' buildingType='advantage' />
                  },
                  {
                    key: 'advantage-parts',
                    label: 'Parts',
                    children: <PartsAndMaterialsTable dataType='parts' buildingType='advantage' />
                  }
                ]}
              />
            },
            {
              key: 'integrity',
              label: 'Integrity',
              children: <Tabs
                defaultActiveKey="integrity-materials"
                size="small"
                items={[
                  {
                    key: 'integrity-materials',
                    label: 'Materials',
                    children: <PartsAndMaterialsTable dataType='materials' buildingType='integrity' />
                  },
                  {
                    key: 'integrity-parts',
                    label: 'Parts',
                    children: <PartsAndMaterialsTable dataType='parts' buildingType='integrity' />
                  }
                ]}
              />
            }
          ]}
        />
      </div>
    </>
  )
}
