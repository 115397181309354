import advantageMaterials from '../data/advantage/materials'
import integrityMaterials from '../data/integrity/materials'

export const getMaterialsDataset = (type) => {
  let materials
  
  switch(type) {
    case 'integrity':
    case 'integrity-cattle':
      materials = integrityMaterials
      break
    case 'advantage':
    default:
      materials = advantageMaterials
  }
  
  return materials.map(a => {return {...a}})
}

export const getMaterials = (material, type) => {
  if (material) {
    if (!Array.isArray(material)) material = [material]
    material = material.map(m => getMaterialObject(m, type)).filter(n => n)
    if (!material.length) return []
  }

  return (material ? material : getMaterialsDataset(type)).map(m => {
    m.poundsPerUnit = getMaterialPoundsPerUnit(m, type)
    m.pricePerUnit = getMaterialPricePerUnit(m, type)
    m.pricePerPound = getMaterialPricePerPound(m, type)

    return m
  })
}

export const getMaterialObject = (material, type) => {
  if (!material) return getMaterials(null, type)

  if (typeof material === 'object' && !Array.isArray(material) && material.id) {
    return material
  }

  material = getMaterialsDataset(type).find(m => m.id === material)

  if (material) return material
  return false
}

export const getMaterialPoundsPerUnit = (material, type) => {
  if (material.poundsPerUnit === false) return 0

  material = getMaterialObject(material, type)

  if (material === false) return 0
  if (material.poundsPerUnit) return material.poundsPerUnit
  if (!material.poundsPerStock || !material.unitDivisor) return 0

  const poundsPerUnit = material.poundsPerStock / material.unitDivisor

  return poundsPerUnit
}

export const getMaterialPricePerPound = (material, type) => {
  if (material.pricePerPound === false) return 0

  material = getMaterialObject(material, type)

  if (material === false) return 0
  if (material.pricePerPound) return material.pricePerPound

  if (!material.pricePerUnit) {
    material.pricePerUnit = getMaterialPricePerUnit(material, type)
    if (!material.pricePerUnit) return 0
  }

  if (!material.poundsPerUnit) return 0

  const pricePerUnit = material.pricePerUnit / material.poundsPerUnit

  return pricePerUnit
}

export const getMaterialPricePerUnit = (material, type) => {
  if (material.pricePerUnit === false) return 0

  material = getMaterialObject(material, type)

  if (material === false) return 0
  if (material.pricePerUnit) return material.pricePerUnit

  if (!material.poundsPerUnit) {
    material.poundsPerUnit = getMaterialPoundsPerUnit(material, type)
    if (!material.poundsPerUnit) return 0
  }

  const pricePerUnit = material.pricePerPound * material.poundsPerUnit

  return pricePerUnit
}
