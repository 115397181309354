import React, { useState } from 'react'
import {
  Input,
  Table,
} from 'antd'

import { getMaterials } from '../calculators/materials'
import advantageMaterials from '../data/advantage/materials'
import integrityMaterials from '../data/integrity/materials'
import { getParts } from '../calculators/parts'
import advantageParts from '../data/advantage/parts'
import integrityParts from '../data/integrity/parts'
import { getManufacturedPartsDataset, getManufacturedPartCalculations } from '../calculators/manufactured-parts'
import { convertStringToTitleCase, generateTableColumns, handleSearch } from '../helpers'

const { Search } = Input

const materialsColumns = ['id', 'description', 'partNumber', 'unitType', 'unitDivisor', 'pricePerPound', 'pricePerUnit', 'poundsPerStock', 'poundsPerUnit']
const partsColumns = ['id', 'description', 'partNumber', 'weight', 'galv', 'steelMrk', 'steelDrop', 'steelCost', 'fastener', 'cover', 'labor', 'totalMrk']
const initialPartsMaterialsColumns = ['id', 'weightMultiplier', 'steelDropMultiplier', 'steelCostMultiplier', 'fastenerMultiplier', 'coverMultiplier']

export default function PartsAndMaterialsTable (props) {
  const allCompareData = { advantageMaterials, integrityMaterials, advantageParts, integrityParts }
  const compareData = allCompareData[props.buildingType + convertStringToTitleCase(props.dataType)]
  const initialData = props.dataType === 'materials' ? getMaterials(null, props.buildingType) : getParts(null, props.buildingType)
  const initialColumns = props.dataType === 'materials' ? materialsColumns : partsColumns
  const [data, setData] = useState(initialData)

  const [columns, setColumns] = useState(generateTableColumns(initialColumns, false, (value) => {
    if (value === null) value = 'null'
    if (value === undefined) value = 'undefined'
    if (value === false) value = 'false'
    if (value === true) value = 'true'
    if (Array.isArray(value)) value = value.join(', ')
    return value
  }, (value, key, item) => {
    if ([null, undefined, false, true].includes(value)) {
      return { color: '#C0C0C0' }
    }

    const compareItem = compareData.find(x => x.id === item.id)
    if (value !== compareItem[key]) {
      return { color: '#1890ff', fontStyle: 'italic', fontWeight: 400 }
    }
  }))

  const [partsMaterialsColumns, setPartsMaterialsColumns] = useState(generateTableColumns(initialPartsMaterialsColumns, false, (value) => {
    if (!Array.isArray(value)) value = [value]

    value = value.map(val => {
      if (Array.isArray(val)) {
        return '(' + val.join(' + ') + ')'
      } else if (typeof val === 'object' && val !== null) {
        let trueMultiplier = '(false)'
        let falseMultiplier = '(false)'

        if (Object.prototype.hasOwnProperty.call(val, 'trueMultiplier')) {
          trueMultiplier = val.trueMultiplier
        } else if (Object.prototype.hasOwnProperty.call(val, 'trueValue')) {
          trueMultiplier = val.trueValue + ' (VALUE)'
        }

        if (Object.prototype.hasOwnProperty.call(val, 'falseMultiplier')) {
          falseMultiplier = val.falseMultiplier
        } else if (Object.prototype.hasOwnProperty.call(val, 'falseValue')) {
          falseMultiplier = val.falseValue + ' (VALUE)'
        }

        return '(' + val.field + ' === ' + val.fieldValue + ' ? ' + trueMultiplier + ' : ' + falseMultiplier + ')'
      } else {
        if (val === null) val = 'null'
        if (val === undefined) val = 'undefined'
        if (val === false) val = 'false'
        if (val === true) val = 'true'

        return val
      }
    }).join(', ')

    return value
  }, (value, key, item) => {
    const falsies = [null, undefined, false, true]

    if (falsies.includes(value) || (Array.isArray(value) && value.some(v => falsies.includes(v)))) {
      return { color: '#C0C0C0' }
    }
  }))

  let missingPartNumbers = []
  let missingEquations = []
  let missingManufacturedPartNumbers = []
  let missingPartManufacturedParts = []
  let missingPartNumbersCount = 0
  let missingEquationsCount = 0
  let missingManufacturedPartNumbersCount = 0
  let missingPartManufacturedPartsCount = 0

  if (props.dataType === 'parts') {
    let manufacturedPartsCalculations = []
    let manufacturedPartsDataset = []

    missingPartNumbers = data.filter(item => !item.partNumber).map(item => item.id)
    missingPartNumbersCount = missingPartNumbers.length

    if (props.buildingType === 'integrity') {
      const integrityManufacturedPartsCalculations = Object.keys(getManufacturedPartCalculations('integrity'))
      const integrityCattleManufacturedPartsCalculations = Object.keys(getManufacturedPartCalculations('integrity-cattle'))
      const integrityManufacturedPartsDataset = getManufacturedPartsDataset('integrity')
      const integrityCattleManufacturedPartsDataset = getManufacturedPartsDataset('integrity-cattle')

      manufacturedPartsCalculations = [...integrityManufacturedPartsCalculations, ...integrityCattleManufacturedPartsCalculations]
      manufacturedPartsDataset = [...integrityManufacturedPartsDataset, ...integrityCattleManufacturedPartsDataset]
    } else {
      manufacturedPartsCalculations = Object.keys(getManufacturedPartCalculations(props.buildingType))
      manufacturedPartsDataset = getManufacturedPartsDataset(props.buildingType)
    }

    missingPartManufacturedParts = data.filter(item => item.partNumber && !manufacturedPartsDataset.map(i => i.partNumber).includes(item.partNumber)).map(item => item.id)
    missingPartManufacturedPartsCount = missingPartManufacturedParts.length
    missingEquations = data.filter(item => !manufacturedPartsCalculations.includes(item.partNumber) && !missingPartManufacturedParts.includes(item.id) && !missingPartNumbers.includes(item.id)).map(item => item.id)
    missingEquationsCount = missingEquations.length
    missingManufacturedPartNumbers = manufacturedPartsDataset.filter(item => !item.partNumber).map(item => item.id)
    missingManufacturedPartNumbersCount = missingManufacturedPartNumbers.length
  }

  return (
    <>
      <h2>{ convertStringToTitleCase(`${props.buildingType} ${props.dataType}`) }</h2>

      { props.dataType === 'parts' &&
        <div>
          <p>Missing Part Numbers: { missingPartNumbersCount }</p>
          <p>Missing Manufactured Part Numbers: { missingManufacturedPartNumbersCount }</p>
          <p>Missing Manufactured Part for Parts: { missingPartManufacturedPartsCount }</p>
          <p>Missing Quantity Equations: { missingEquationsCount }</p>
        </div>
      }

      <Search
        placeholder='Search...'
        onSearch={ input => handleSearch(input, initialData, setData) }
        onChange={ e => handleSearch(e.target.value, initialData, setData) }
        style={{ width: '50%' }}
        allowClear />

      <Table
        columns={ columns }
        dataSource={ data }
        expandedRowRender={ (item) => {
          if (item.material && Array.isArray(item.material)) {
            return (
              <>
                <h3>material</h3>
                <Table
                  columns={ partsMaterialsColumns }
                  dataSource={ item.material }
                  pagination={ false }
                  rowKey='id'
                  size='small' />
              </>
            )
          }
        }}
        pagination={{ pageSize: 50 }}
        rowClassName={ (item) => (!item.material || !item.material.length) ? 'no-expand' : null }
        onRow={ (item) => {
          if (props.dataType === 'parts') {
            if (!item.partNumber) {
              return { style: { backgroundColor: '#FFD580' } }
            } else if (missingPartManufacturedParts.includes(item.id)) {
              return { style: { backgroundColor: '#DDD5F3' } }
            } else if (missingEquations.includes(item.id)) {
              return { style: { backgroundColor: '#FFCCCB' } }
            }
          }
        }}
        rowKey='id'
        scroll={{ x: true }}
        size='small' />

      <div style={{ width: '50%' }}>
        <p><b>Color Codes</b></p>
        <p>Hard-coded value</p>
        <p style={{ color: '#C0C0C0' }}>Programmical value</p>
        <p style={{ color: '#1890ff', fontStyle: 'italic', fontWeight: 400 }}>Value generated by calculator</p>
        <p style={{ backgroundColor: '#FFD580' }}>Part doesn't have a part number</p>
        <p style={{ backgroundColor: '#DDD5F3' }}>Part doesn't have a manufactured part associated with it</p>
        <p style={{ backgroundColor: '#FFCCCB' }}>Part's associated manufactured part does not have a quantity equation</p>
      </div>
    </>
  )
}