import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Alert, Form, Input, Button } from 'antd'

export default function PasswordResetRequest () {
  const [ loading, setLoading ] = useState(false)
  const [ successMsg, setSuccessMsg ] = useState('')
  const [ errMsg, setErrMsg ] = useState('')

  const requestPasswordReset = (data) => {
    setLoading(true)
    setErrMsg('')
    setSuccessMsg('')

    axios.post('/api/request-password-reset', {
      ...data
    }, {
      withCredentials: true
    }).then((res) => {
      if (res.data.success) {
        setSuccessMsg('An email was sent to your inbox with instructions on how to reset your password.')
      }

     setLoading(false)
    }, () => {
      setErrMsg('An account could not be found with that username or email address.')
      setLoading(false)
    })
  }

  return ( (loading) ? <p>Loading...</p> :
    <>
      <div id="password-reset-request" className="center-page-block">
        <img src="/logo.png" className="logo" alt="AccuSteel logo" />

        <h1>Request Password Reset</h1>

        <Form
          name="basic"
          onFinish={ requestPasswordReset }
          autoComplete="off"
          layout="vertical"
        >
          { successMsg && <Alert message={ successMsg } type="success" showIcon /> }
          { errMsg && <Alert message={ errMsg } type="error" showIcon /> }

          <Form.Item
            label="Username or Email Address"
            name="login"
            rules={[{ required: true, message: 'Please enter a username or email address.' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit">Send Password Reset Request</Button>
          </Form.Item>
        </Form>

        <div style={{ textAlign: 'center' }}>
          <Link to="/login">Go back to login</Link>
        </div>
      </div>
    </>
  )
}
