import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import axios from 'axios'
import {
  Button,
  Col,
  message,
  PageHeader,
  Row
} from 'antd'

import EditBuildingEstimate from './building/edit'

export default function CreateEstimate () {
  const navigate = useNavigate()
  const createNewEstimate = (values) => {
    axios.post('/api/estimates', {
      ...values
    }, {
      withCredentials: true
    }).then((res) => {
      navigate('/estimates')
      message.success('Your estimate was successfully saved!')
    }, (res) => {
      console.error(res)
      message.error('An unknown error occured while saving your estimate.')
    })
  }

  return (
    <div className="estimate create-estimate">
      <EditBuildingEstimate onFinish={ createNewEstimate } onCancel={ (e) => navigate('/estimates/new') } />
    </div>
  )
}