import {
  FileImageOutlined,
  FilePdfOutlined,
  FileZipOutlined,
  GlobalOutlined,
  ProfileOutlined,
} from '@ant-design/icons'

export default function FileTypeIcon (props) {
  const file = props.file ?? ''
  const ext = file.split('.').pop()
  
  switch(ext) {
    case 'eps':
    case 'gif':
    case 'jpg':
    case 'jpeg':
    case 'png':
      return <FileImageOutlined { ...props } />
    case 'csv':
    case 'xlsx':
      return <ProfileOutlined { ...props } />
    case 'pdf':
      return <FilePdfOutlined { ...props } />
    case '7z':
    case 'rar':
    case 'zip':
      return <FileZipOutlined { ...props } />
    default:
      return <GlobalOutlined { ...props } />
  }
}