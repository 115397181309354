import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react'
import {
  Card,
  PageHeader,
  Table
} from 'antd'
import * as dayjs from 'dayjs'
import NumberFormat from 'react-number-format'

import { generateBuildingEstimateXLSX } from '../../../components/BuildingEstimateExport'

import advantageBuildingOptions from '../../../data/advantage/options'
import advantageBuildingAmounts from '../../../data/advantage/amounts'
import advantageBuildingAddons from '../../../data/advantage/addons'

import integrityBuildingOptions from '../../../data/integrity/options'
import integrityBuildingAmounts from '../../../data/integrity/amounts'
import integrityBuildingAddons from '../../../data/integrity/addons'

import integrityCattleBuildingOptions from '../../../data/integrity/cattle/options'
import integrityCattleBuildingAmounts from '../../../data/integrity/cattle/amounts'
import integrityCattleBuildingAddons from '../../../data/integrity/cattle/addons'

import { convertCamelCaseToTitleCase, convertDashCaseToTitleCase, convertStringToTitleCase, generateTableColumns } from '../../../helpers'

import { userContext } from '../../../context/User.js'

const ViewBuildingEstimate = forwardRef((props, ref) => {
  const data = props.data
  const totals = data.totals
  const [ estimateOptions, setEstimateOptions ] = useState([])
  const user = useContext(userContext)
  const isAdmin = user.permissions.includes('manage-estimates')

  const getBuildingTypeData = (buildingType) => {
    switch(buildingType) {
      case 'integrity':
        return [ ...integrityBuildingOptions, ...integrityBuildingAmounts, ...integrityBuildingAddons ]
      case 'integrity-cattle':
        return [ ...integrityCattleBuildingOptions, ...integrityCattleBuildingAmounts, ...integrityCattleBuildingAddons ]
      case 'advantage':
      default:
        return [ ...advantageBuildingOptions, ...advantageBuildingAmounts, ...advantageBuildingAddons ]
    }
  }

  const populateEstimateOptions = (inputData) => {
    const optionData = getBuildingTypeData(inputData.buildingType)

    const options = Object.keys(inputData)
      .map(key => {
        let option = convertStringToTitleCase(convertDashCaseToTitleCase(convertCamelCaseToTitleCase(key)))
        let value = inputData[key] === true ? 'Yes' : (typeof inputData[key] === 'string' ? convertStringToTitleCase(convertDashCaseToTitleCase(inputData[key])) : inputData[key])
        const keyData = optionData.find(item => item.name === key || item.slug === key)

        if (keyData) {
          option = keyData.label ?? keyData.description ?? keyData.name ?? key
  
          if (keyData.options) {
            const valueData = keyData.options.find(item => item.value === inputData[key])

            if (valueData && valueData.label) {
              value = valueData.label
            }
          }
        }

        return { key, option, value }
      })
      .filter(n => n.value)

    setEstimateOptions(options)
  }

  useImperativeHandle(ref, () => ({
    onDownload() {
      generateBuildingEstimateXLSX(data, estimateOptions)
    }
  }))

  useEffect(() => {
    populateEstimateOptions(data.inputData)
  }, [])

  return ( (!data) ? <p>Could not find estimate</p> :
    <>
      <PageHeader title={ data.title } onBack={ props.onBack } />

      <b>Created Date</b>: { dayjs(data.createdDate).format('M/D/YY h:mma') }<br />
      <b>Last Modified Date</b>: { dayjs(data.modifiedDate).format('M/D/YY h:mma') }<br />
      { data.requestedPickUpDate && <><b>Requested Pick-Up Date</b>: { dayjs(data.requestedPickUpDate).format('M/D/YY') }<br /></> }
      <b>Creator</b>: { data.creator ? (data.creator.first_name + ' ' + data.creator.last_name) : '(User Not Found)' }<br />
      <b>Dealer</b>: { data.dealer ? (data.dealer.first_name + ' ' + data.dealer.last_name) : '(User Not Found)' }<br />
      <b>Customer</b>: { data.customerName ?? <i>(None)</i> }<br />
      <b>Customer Address</b>: { data.customerAddress ?? <i>(None)</i> }<br />
      <b>Status</b>: { convertStringToTitleCase(data.status) }<br />

      <br /><br />
      <div id="view-estimate-options" style={{ width: '50%' }}>
        <h3>Options</h3>
        <Table
          columns={ generateTableColumns(['option', 'value']) }
          rowKey="key"
          dataSource={ estimateOptions }
          pagination={ false }
          size="small"
          showHeader={ false }
        />
      </div>
      
      <br /><br />
      <div id="view-estimate-parts">
        <h3>Parts</h3>
        <Table
          columns={ generateTableColumns(['partNumber', 'description', 'requiredQty', 'addQty'], true) }
          rowKey="id"
          dataSource={
            data.parts.map(part => {
              if (part.alternate && data && data.inputData) {
                const fieldValue = data.inputData[part.alternate.field]

                if (fieldValue === part.alternate.value) {
                  if (part.alternate.properties.description) {
                    part.description = part.alternate.properties.description
                  }

                  if (part.alternate.properties.partNumber) {
                    part.partNumber = part.alternate.properties.partNumber
                  }
                }
              }

              return part
            })
          }
          pagination={ false }
          size="small"
        />
      </div>

      <br /><br />
      <Card id="view-estimate-totals" title="Totals" style={{ display: 'inline-block' }}>
        <p><b>Sqft</b>: { <NumberFormat value={totals.sqft} displayType={'text'} thousandSeparator={true} decimalScale={0} /> }</p>
        <p><b>Shipping Weight</b>: { <NumberFormat value={totals.weight} displayType={'text'} thousandSeparator={true} /> }</p>
        <p><b>Suggested Retail Price</b>: { <NumberFormat value={totals.suggestedPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> }</p>
        { isAdmin && <p><b>Dealer Discount</b>: { <NumberFormat value={totals.discountPercentage} displayType={'text'} suffix={'%'} /> }</p> }
        <p><b>Dealer Price</b>: { <NumberFormat value={totals.amountDue} displayType={'text'} thousandSeparator={true} prefix={'$'} /> }</p>
        <p><b>Price Per Sqft</b>: { <NumberFormat value={totals.pricePerSqFt} displayType={'text'} thousandSeparator={true} prefix={'$'} /> }</p>
        <p><b>Deposit Required</b>: { <NumberFormat value={totals.deposit} displayType={'text'} thousandSeparator={true} prefix={'$'} /> }</p>
        <p><b>Amount Due at Pickup</b>: { <NumberFormat value={totals.amountDueAtPickup} displayType={'text'} thousandSeparator={true} prefix={'$'} /> }</p>
      </Card>
    </>
  )
})

export default ViewBuildingEstimate