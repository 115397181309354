import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Form, Input, Button } from 'antd'

export default function Login () {
  const [form] = Form.useForm()
  const [userdata, setUserdata] = useState({})
  const [errMsg, setErrMsg] = useState('')

  const register = () => {
    
    axios.post('/api/register', {
      ...userdata
    }, { withCredentials: true })
    .then((res) => {
      if (res.data.success) {
        window.location.href = '/'
      }
    })
    .catch((err) => {
      if (err.response) {
        const status = err.response.status
        
        if (status === 409) {
          const errorField = err.response.data.errorField

          if (errorField === 'username') {
            form.setFields([
              { name: 'username', errors: ['An account with that username already exists.'] },
            ])
          } else if (errorField === 'email') {
            form.setFields([
              { name: 'email', errors: ['An account with that email already exists.'] },
            ])
          }
        } else {
          setErrMsg('An unknown error has occurred.')
        }
      }
    })
  }

  const setUserdataField = (field, value) => {
    userdata[field] = value
    setUserdata(userdata)
  }

  return (
    <div id="register" className="center-page-block">
      <img src="/logo.png" className="logo" alt="AccuSteel logo" />

      <h1>Register</h1>

      <Form
        form={ form }
        name="basic"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        onFinish={register}
        autoComplete="off"
      >
        { errMsg &&
          <div role="alert" className="ant-form-item-explain-error" style={{ textAlign: 'center' }}>{ errMsg }</div> }

        <Form.Item
          label="First Name"
          name="first_name"
          rules={[{ required: true, message: 'Please enter your first name.' }]}
        >
          <Input onChange={e => setUserdataField('first_name', e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="last_name"
          rules={[{ required: true, message: 'Please enter your last name.' }]}
        >
          <Input onChange={e => setUserdataField('last_name', e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Company"
          name="company"
          rules={[{ required: true, message: 'Please enter your company.' }]}
        >
          <Input onChange={e => setUserdataField('company', e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please enter your username.' }]}
        >
          <Input onChange={e => setUserdataField('username', e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please enter your password.' }]}
        >
          <Input.Password onChange={e => setUserdataField('password', e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirm_password"
          dependencies={['password']}
          rules={[
            { required: true, message: 'Please confirm your password.' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }

                return Promise.reject(new Error('The two passwords that you entered do not match!'))
              },
            })
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            { required: true, message: 'Please enter your email address.' },
            { type: 'email', message: 'Please enter a valid email address.' }
          ]}
        >
          <Input onChange={e => setUserdataField('email', e.target.value)} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 10, span: 14 }}>
          <Button type="primary" htmlType="submit">Register</Button>
        </Form.Item>
      </Form>

      <div style={{ textAlign: 'center' }}>
        <Link to="/login">Go back to login</Link>
      </div>
    </div>
  )
}
