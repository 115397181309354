import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import { Alert, Form, Input, Button } from 'antd'

export default function Login () {
  const [errMsg, setErrMsg] = useState('')
  const location = useLocation()

  const login = (credentials) => {
    axios.post('/api/login', {
      ...credentials
    }, {
      withCredentials: true
    }).then((res) => {
      const path = location.pathname.replace(/^\/+|\/+$/g, '').split('/')
      const redirectPath = path[0] === 'login' ? '/' : '/' + path.join('/')
      window.location.replace(redirectPath)
    }, () => {
      setErrMsg('You have entered an invalid username or password.')
    })
  }

  return (
    <div id="login" className="center-page-block">
      <img src="/logo-alt.png" className="logo" alt="AccuSteel logo" />

      <h1>Login</h1>

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={login}
        autoComplete="off"
      >
        { errMsg && <Alert message={ errMsg } type="error" showIcon /> }

        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password.' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">Login</Button>
        </Form.Item>

        <div style={{ textAlign: 'center' }}>
          {/* <Link to="/register">Create an Account</Link><br /> */}
          <Link to="/reset-password">Forgot Your Password?</Link>
        </div>
      </Form>
    </div>
  )
}
