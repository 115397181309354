import React, { useState } from 'react'
import axios from 'axios'
import {
  Button,
  Col,
  notification,
  Input,
} from 'antd'
import { UserOutlined } from '@ant-design/icons'

const notify = (title, text, type) => {
  notification[type]({
    message: title,
    description: text
  })
}

export default function Users () {
  const [loading, setLoading] = useState(false)
  const [inviteEmail, setInviteEmail] = useState('')

  const invite = (e) => {
    setLoading(true)

    axios.post('/api/invite', {
      email: inviteEmail
    }, {
      withCredentials: true
    })
      .then(res => {
        notify(
          'Invite Sent',
          'An email with an invite link to create an account was sent to ' + inviteEmail,
          'success'
        )

        setInviteEmail('')
        setLoading(false)
      })
      .catch((e) => {
        notify(
          'Error Sending Invite',
          'An error occured while sending an invite to ' + inviteEmail,
          'error'
        )

        setLoading(false)
      })
  }

  return (
    <Col span={12}>
      <Input.Group compact>
        <Input
          style={{ width: 'calc(100% - 200px)' }}
          placeholder="Email Address"
          prefix={<UserOutlined />}
          onChange={e => setInviteEmail(e.target.value)}
          value={inviteEmail}
        />
        <Button type="primary" onClick={e => invite()}>Invite User</Button>
      </Input.Group>
    </Col>
  )
}
