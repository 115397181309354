import { calculateBelowZeroDistance, calculateLengthByRafterSpacing } from './'

export const manufacturedPartCalculations = {
  '41010014': values => calculateLengthByRafterSpacing(values, '30s', 2),
  '41010015': values => calculateLengthByRafterSpacing(values, '40s', 1),
  '48290000': values => calculateLengthByRafterSpacing(values, '40s', 2),
  '48255000': values => calculateLengthByRafterSpacing(values, '50s', 3),
  '41010152': values => calculateLengthByRafterSpacing(values, ['60s', '50hp'], 2),
  '41010153': values => calculateLengthByRafterSpacing(values, ['70s', '60hp'], 2),
  '41010154': values => calculateLengthByRafterSpacing(values, ['60s', '70s', '80s'], 2),
  '41010216': values => calculateLengthByRafterSpacing(values, ['30hp', '40hp', '50hp', '60hp'], 2),
  '41010287': values => calculateLengthByRafterSpacing(values, '80s', 2),
  '41010288': values => calculateLengthByRafterSpacing(values, '80s', 1),
  '41010300': values => calculateLengthByRafterSpacing(values, '40hp', 1),
  '41010396': values => calculateLengthByRafterSpacing(values, '30hp', 1),
  '41010016': values => values['wall-brackets'] ? ((parseInt(values.buildingLength) / parseInt(values.rafterSpacing) + 1) * 2) : null,
  '31330001': values => {
    if (values['ground-mount-cover']) {
      let qty = ((parseInt(values.buildingLength) / parseInt(values.rafterSpacing) + 1) * 2)
      const individualBayKeder = (values.mainCoverTerminationStyle === 'individual-bay-keder')

      qty = (qty * (individualBayKeder ? 2 : 1) - (individualBayKeder ? 4 : 0) * (values['wide-baseplates'] ? 0 : 1))

      return qty
    }
  },
  '41010397': values => calculatePurlinQty(values, 4),
  '41010413': values => calculatePurlinQty(values, 5),
  '41010414': values => calculatePurlinQty(values, 6),
  '41010017': values => calculatePurlinQty(values, 8),
  '41010018': values => calculatePurlinQty(values, 10),
  '48230000': values => calculatePurlinQty(values, 12),
  '41010019': values => calculatePurlinQty(values, 14),
  '41010020': values => calculatePurlinQty(values, 16),
  '41010415': values => calculateEndRafterBraceQty(values, 4),
  '41010416': values => calculateEndRafterBraceQty(values, 5),
  '41010417': values => calculateEndRafterBraceQty(values, 6),
  '41010021': values => calculateEndRafterBraceQty(values, 8),
  '48285000': values => calculateEndRafterBraceQty(values, 10),
  '48235000': values => calculateEndRafterBraceQty(values, 12),
  '47005001': values => calculateEndRafterBraceQty(values, 14),
  '41010022': values => calculateEndRafterBraceQty(values, 16),
  '41010398': values => calculateEndRafterBraceQty(values, 4, true),
  '41010418': values => calculateEndRafterBraceQty(values, 5, true),
  '41010419': values => calculateEndRafterBraceQty(values, 6, true),
  '41010155': values => calculateEndRafterBraceQty(values, 8, true),
  '41010156': values => calculateEndRafterBraceQty(values, 10, true),
  '41010157': values => calculateEndRafterBraceQty(values, 12, true),
  '41010158': values => calculateEndRafterBraceQty(values, 14, true),
  '41010159': values => calculateEndRafterBraceQty(values, 16, true),
  '41010023': values => calculateTubeRafterInsert(values, {
    '30s': 1,
    '40s': 2,
    '50s': 2,
  }),
  '41010160': values => calculateTubeRafterInsert(values, {
    '60s': 3,
    '70s': 3,
    '80s': 4,
    '30hp': 2,
    '40hp': 2,
    '50hp': 3,
    '60hp': 3
  }),
  '41010024': values => calculateTubeRafterInsert(values, {
    '30s': 1,
    '40s': 2,
    '50s': 2,
    '60s': 3,
    '70s': 3,
    '80s': 4,
    '30hp': 2,
    '40hp': 2,
    '50hp': 3,
    '60hp': 3
  }),
  '41010025': values => calculateBasePlateQty(values, false, false),
  '41010026': values => calculateBasePlateQty(values, false, false),
  '41010217': values => calculateBasePlateQty(values, true, false),
  '41010218': values => calculateBasePlateQty(values, true, false),
  '41010628': values => calculateBasePlateQty(values, false, true),
  '41010629': values => calculateBasePlateQty(values, false, true),
  '41010630': values => calculateBasePlateQty(values, true, true),
  '41010631': values => calculateBasePlateQty(values, true, true),
  '41030139': values => values['intermediate-single-hole-winche-brackets-between-rafters'] ? ((parseInt(values.buildingLength) / parseInt(values.rafterSpacing) + 1) * 2) : null,
  '41010027': values => calculateSwagedPullPipe(values),
  '64440000': values => {
    if (values.coverMaterial === 'enduro-loc-fr' && values.mainCoversColor === 'white') {
      return calculateCoverBelowZeroDistance((calculateBelowZeroDistance(values.sidewallCoverBelowZeroDistance) * 2), values)
    }
  },
  '64310000': values => {
    if (values.coverMaterial === 'enduro-loc-fr' && values.mainCoversColor === 'tan') {
      return calculateCoverBelowZeroDistance((calculateBelowZeroDistance(values.sidewallCoverBelowZeroDistance) * 2), values)
    }
  },
  '64450000': values => {
    if (values.coverMaterial === 'enduro-loc-fr' && values.mainCoversColor === 'green') {
      return calculateCoverBelowZeroDistance((calculateBelowZeroDistance(values.sidewallCoverBelowZeroDistance) * 2), values)
    }
  },
  '64435000': values => {
    if (values.coverMaterial === 'enduro-loc' && values.mainCoversColor === 'white') {
      return calculateCoverBelowZeroDistance((calculateBelowZeroDistance(values.sidewallCoverBelowZeroDistance) * 2), values)
    }
  },
  '64120000': values => {
    if (values.coverMaterial === 'enduro-loc' && values.mainCoversColor === 'tan') {
      return calculateCoverBelowZeroDistance((calculateBelowZeroDistance(values.sidewallCoverBelowZeroDistance) * 2), values)
    }
  },
  '64130000': values => {
    if (values.coverMaterial === 'enduro-loc' && values.mainCoversColor === 'green') {
      return calculateCoverBelowZeroDistance((calculateBelowZeroDistance(values.sidewallCoverBelowZeroDistance) * 2), values)
    }
  },
  '64150000': values => {
    if (values.coverMaterial === 'enduro-loc' && values.mainCoversColor === 'red') {
      return calculateCoverBelowZeroDistance((calculateBelowZeroDistance(values.sidewallCoverBelowZeroDistance) * 2), values)
    }
  },
  '64140000': values => {
    if (values.coverMaterial === 'enduro-loc' && values.mainCoversColor === 'blue') {
      return calculateCoverBelowZeroDistance((calculateBelowZeroDistance(values.sidewallCoverBelowZeroDistance) * 2), values)
    }
  },
  '91215000': values => calculateKederTrack(values),
  '63055012': values => {
    if (values.kederCapChannel === '20' && (values.mainCoverTerminationStyle === 'individual-bay-keder' || values.mainCoverTerminationStyle === 'sectional-keder')) {
      return calculateWhiteCapChannel(values)
    }
  },
  '63055020': values => {
    if (values.kederCapChannel === 'continuous' && (values.mainCoverTerminationStyle === 'individual-bay-keder' || values.mainCoverTerminationStyle === 'sectional-keder')) {
      return calculateWhiteCapChannel(values)
    }
  },
  '23560000': values => values['5-8ths-threaded-rods'] ? (((values.buildingLength / values.rafterSpacing) + 1) * 6) : null,
  '41630000': values => (parseInt(values.rafterSpacing) <= 10) ? calculateCableAssembly(values.buildingWidth) : null,
  '41175000': values => (parseInt(values.rafterSpacing) > 10) ? calculateCableAssembly(values.buildingWidth) : null,
  '41010801': values => calculateAdvantageCableAttachmentClip(values),
  '31105000': values => values.buildingWidth === '80s' ? 8 : 4,
  '41010297': values => {
    if (values['barrier-backing-plate']) {
      return values['wall-brackets'] ? ((parseInt(values.buildingLength) / parseInt(values.rafterSpacing) + 1) * 2) : null
    }
  },
  '31190000': values => {
    const conditions = {
      '30s':  [1, 2, 2, 2, 2],
      '40s':  [2, 2, 2, 2, 2],
      '50s':  [2, 2, 2, 2, 2],
      '60s':  [4, 4, 4, 4, 4],
      '70s':  [4, 4, 4, 6, 6],
      '80s':  [4, 4, 4, 4, 4],
      '30hp': [1, 2, 2, 2, 2],
      '40hp': [4, 2, 4, 4, 4],
      '50hp': [4, 4, 4, 4, 4],
      '60hp': [4, 4, 4, 4, 6]
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return (
        condition[0] *
        values.solidEndwallKit +
        condition[1] *
        values['19-7-1-2-door-opening-endwall-kit'] +
        condition[2] *
        values['16-0-door-opening-endwall-kit'] +
        condition[3] *
        values['14-0-door-opening-endwall-kit'] +
        condition[4] *
        values['12-0-door-opening-endwall-kit'] +
        (values.endwallSteelBelowZeroDistance === '0-0' ? 0 : 2)
      )
    }
  },
  '41325000': values => {
    const endwalls = (
      parseInt(values['19-7-1-2-door-opening-endwall-kit']) +
      parseInt(values['16-0-door-opening-endwall-kit']) +
      parseInt(values['14-0-door-opening-endwall-kit']) +
      parseInt(values['12-0-door-opening-endwall-kit'])
    )
    
    let quantity = endwalls * 3
  
    if (endwalls <= 0) {
      quantity += getTallGatherDoorKitCount(values)
    }
  
    return quantity
  },
  '41010161': values => {
    let qty = 0

    const conditions = {
      '30s':  [0, 0, 0, 0, 0],
      '40s':  [2, 0, 0, 0, 0],
      '50s':  [2, 0, 2, 0, 0],
      '60s':  [4, 2, 2, 2, 2],
      '70s':  [4, 2, 2, 2, 2],
      '80s':  [8, 4, 4, 4, 4],
      '30hp': [0, 0, 0, 0, 0],
      '40hp': [4, 0, 2, 2, 2],
      '50hp': [4, 2, 2, 2, 2],
      '60hp': [4, 2, 2, 2, 2]
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      qty = (
        condition[0] *
        values.solidEndwallKit +
        condition[1] *
        values['19-7-1-2-door-opening-endwall-kit'] +
        condition[2] *
        values['16-0-door-opening-endwall-kit'] +
        condition[3] *
        values['14-0-door-opening-endwall-kit'] +
        condition[4] *
        values['12-0-door-opening-endwall-kit']
      )
    }

    if (values.endwallSteelBelowZeroDistance !== '0-0') {
      qty += ((
        (
          calculateQCell159(values) +
          calculateQCell160(values) +
          calculateQCell161(values) +
          calculateQCell162(values) +
          calculateQCell163(values)
        ) +
        (
          calculateQCell172(values) +
          calculateQCell173(values) +
          calculateQCell174(values) +
          calculateQCell175(values) +
          calculateQCell176(values)
        )
      ) * 2)
    }

    return qty
  },
  '41010162': values => {
    let qty = 0

    const doorKits = (
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit']
    )

    const conditions = {
      '30s':  [1, 4, 4, 4, 4],
      '40s':  [2, 4, 4, 4, 4],
      '50s':  [2, 4, 4, 4, 4],
      '60s':  [2, 4, 4, 4, 4],
      '70s':  [2, 4, 4, 6, 6],
      '80s':  [6, 8, 8, 8, 8],
      '30hp': [1, 4, 4, 4, 4],
      '40hp': [2, 4, 4, 4, 4],
      '50hp': [2, 6, 6, 6, 6],
      '60hp': [2, 6, 6, 6, 8]
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      qty = (
        condition[0] *
        values.solidEndwallKit +
        condition[1] *
        values['19-7-1-2-door-opening-endwall-kit'] +
        condition[2] *
        values['16-0-door-opening-endwall-kit'] +
        condition[3] *
        values['14-0-door-opening-endwall-kit'] +
        condition[4] *
        values['12-0-door-opening-endwall-kit']
      )
    } else if (values.buildingWidth === '80s') {
      qty = (doorKits * 2)
    }

    return qty + (doorKits * 2)
  },
  '41010168': values => 0,
  '41010163': values => calculateSquareTube(values, 20),
  '41010167': values => calculateSquareTube(values, 24),
  '41030407': values => 0,
  '41010623': values => (
    (
      calculateQCell161(values) +
      calculateQCell162(values) +
      calculateQCell163(values)
    ) + (
      calculateQCell174(values) +
      calculateQCell175(values) +
      calculateQCell176(values)
    )
  ),
  '41010626': values => (calculateQCell161(values) + calculateQCell174(values)),
  '41010625': values => (calculateQCell162(values) + calculateQCell175(values)),
  '41010624': values => (calculateQCell163(values) + calculateQCell176(values)),
  '41010627': (values, parts) => (
    (
      parts['41010626'] +
      parts['41010625'] +
      parts['41010624']
    ) * 2
  ),
  '41010164': values => (5 * (parseInt(values['12-0-x-up-to-16-tall-gather-door-kit']) + parseInt(values['14-0-x-up-to-16-tall-gather-door-kit']) + parseInt(values['16-0-x-up-to-16-tall-gather-door-kit'])) + (6 * parseInt(values['19-7-1-2-x-up-to-20-tall-gather-door-kit']))),
  '31050056': values => (2 * (parseInt(values['12-0-x-up-to-16-tall-gather-door-kit']) + parseInt(values['14-0-x-up-to-16-tall-gather-door-kit']) + parseInt(values['16-0-x-up-to-16-tall-gather-door-kit']))),
  '31050057': values => (2 * parseInt(values['19-7-1-2-x-up-to-20-tall-gather-door-kit'])),
  '31035000': values => 0,
  '31040000': values => 0,
  '41030217': values => getTallGatherDoorKitCount(values),
  '31010006': values => (3 * getTallGatherDoorKitCount(values)),
  '41011105': values => 0,
  '31010543': values => 0,
  '41010165': values => values['walk-door-kit'],
  '41010166': values => (2 * values['walk-door-kit']),
  'EWCFR30SWW': values => calculateCover(values, 'EWCFR30SWW'),
  'EWCFR40SWW': values => calculateCover(values, 'EWCFR40SWW'),
  'EWCFR50SWW': values => calculateCover(values, 'EWCFR50SWW'),
  'EWCFR60SWW': values => calculateCover(values, 'EWCFR60SWW'),
  'EWCFR70SWW': values => calculateCover(values, 'EWCFR70SWW'),
  'EWCFR80SWW': values => calculateCover(values, 'EWCFR80SWW'),
  'EWCFR30HPWW': values => calculateCover(values, 'EWCFR30HPWW'),
  'EWCFR40HPWW': values => calculateCover(values, 'EWCFR40HPWW'),
  'EWCFR50HPWW': values => calculateCover(values, 'EWCFR50HPWW'),
  'EWCFR60HPWW': values => calculateCover(values, 'EWCFR60HPWW'),
  'EWCFR30STW': values => calculateCover(values, 'EWCFR30STW'),
  'EWCFR40STW': values => calculateCover(values, 'EWCFR40STW'),
  'EWCFR50STW': values => calculateCover(values, 'EWCFR50STW'),
  'EWCFR60STW': values => calculateCover(values, 'EWCFR60STW'),
  'EWCFR70STW': values => calculateCover(values, 'EWCFR70STW'),
  'EWCFR80STW': values => calculateCover(values, 'EWCFR80STW'),
  'EWCFR30HPTW': values => calculateCover(values, 'EWCFR30HPTW'),
  'EWCFR40HPTW': values => calculateCover(values, 'EWCFR40HPTW'),
  'EWCFR50HPTW': values => calculateCover(values, 'EWCFR50HPTW'),
  'EWCFR60HPTW': values => calculateCover(values, 'EWCFR60HPTW'),
  'EWCFR30SGW': values => calculateCover(values, 'EWCFR30SGW'),
  'EWCFR40SGW': values => calculateCover(values, 'EWCFR40SGW'),
  'EWCFR50SGW': values => calculateCover(values, 'EWCFR50SGW'),
  'EWCFR60SGW': values => calculateCover(values, 'EWCFR60SGW'),
  'EWCFR70SGW': values => calculateCover(values, 'EWCFR70SGW'),
  'EWCFR80SGW': values => calculateCover(values, 'EWCFR80SGW'),
  'EWCFR30HPGW': values => calculateCover(values, 'EWCFR30HPGW'),
  'EWCFR40HPGW': values => calculateCover(values, 'EWCFR40HPGW'),
  'EWCFR50HPGW': values => calculateCover(values, 'EWCFR50HPGW'),
  'EWCFR60HPGW': values => calculateCover(values, 'EWCFR60HPGW'),
  'EWC30SWW': values => calculateCover(values, 'EWC30SWW'),
  'EWC40SWW': values => calculateCover(values, 'EWC40SWW'),
  'EWC50SWW': values => calculateCover(values, 'EWC50SWW'),
  'EWC60SWW': values => calculateCover(values, 'EWC60SWW'),
  'EWC70SWW': values => calculateCover(values, 'EWC70SWW'),
  'EWC80SWW': values => calculateCover(values, 'EWC80SWW'),
  'EWC30HPWW': values => calculateCover(values, 'EWC30HPWW'),
  'EWC40HPWW': values => calculateCover(values, 'EWC40HPWW'),
  'EWC50HPWW': values => calculateCover(values, 'EWC50HPWW'),
  'EWC60HPWW': values => calculateCover(values, 'EWC60HPWW'),
  'EWC30STW': values => calculateCover(values, 'EWC30STW'),
  'EWC40STW': values => calculateCover(values, 'EWC40STW'),
  'EWC50STW': values => calculateCover(values, 'EWC50STW'),
  'EWC60STW': values => calculateCover(values, 'EWC60STW'),
  'EWC70STW': values => calculateCover(values, 'EWC70STW'),
  'EWC80STW': values => calculateCover(values, 'EWC80STW'),
  'EWC30HPTW': values => calculateCover(values, 'EWC30HPTW'),
  'EWC40HPTW': values => calculateCover(values, 'EWC40HPTW'),
  'EWC50HPTW': values => calculateCover(values, 'EWC50HPTW'),
  'EWC60HPTW': values => calculateCover(values, 'EWC60HPTW'),
  'EWC30SGW': values => calculateCover(values, 'EWC30SGW'),
  'EWC40SGW': values => calculateCover(values, 'EWC40SGW'),
  'EWC50SGW': values => calculateCover(values, 'EWC50SGW'),
  'EWC60SGW': values => calculateCover(values, 'EWC60SGW'),
  'EWC70SGW': values => calculateCover(values, 'EWC70SGW'),
  'EWC80SGW': values => calculateCover(values, 'EWC80SGW'),
  'EWC30HPGW': values => calculateCover(values, 'EWC30HPGW'),
  'EWC40HPGW': values => calculateCover(values, 'EWC40HPGW'),
  'EWC50HPGW': values => calculateCover(values, 'EWC50HPGW'),
  'EWC60HPGW': values => calculateCover(values, 'EWC60HPGW'),
  'EWC30SRW': values => calculateCover(values, 'EWC30SRW'),
  'EWC40SRW': values => calculateCover(values, 'EWC40SRW'),
  'EWC50SRW': values => calculateCover(values, 'EWC50SRW'),
  'EWC60SRW': values => calculateCover(values, 'EWC60SRW'),
  'EWC70SRW': values => calculateCover(values, 'EWC70SRW'),
  'EWC80SRW': values => calculateCover(values, 'EWC80SRW'),
  'EWC30HPRW': values => calculateCover(values, 'EWC30HPRW'),
  'EWC40HPRW': values => calculateCover(values, 'EWC40HPRW'),
  'EWC50HPRW': values => calculateCover(values, 'EWC50HPRW'),
  'EWC60HPRW': values => calculateCover(values, 'EWC60HPRW'),
  'EWC30SBW': values => calculateCover(values, 'EWC30SBW'),
  'EWC40SBW': values => calculateCover(values, 'EWC40SBW'),
  'EWC50SBW': values => calculateCover(values, 'EWC50SBW'),
  'EWC60SBW': values => calculateCover(values, 'EWC60SBW'),
  'EWC70SBW': values => calculateCover(values, 'EWC70SBW'),
  'EWC80SBW': values => calculateCover(values, 'EWC80SBW'),
  'EWC30HPBW': values => calculateCover(values, 'EWC30HPBW'),
  'EWC40HPBW': values => calculateCover(values, 'EWC40HPBW'),
  'EWC50HPBW': values => calculateCover(values, 'EWC50HPBW'),
  'EWC60HPBW': values => calculateCover(values, 'EWC60HPBW'),
  'DC19': values => values['19-7-1-2-x-up-to-20-tall-gather-door-kit'],
  'DC16': values => values['16-0-x-up-to-16-tall-gather-door-kit'],
  'DC14': values => values['14-0-x-up-to-16-tall-gather-door-kit'],
  'DC12': values => values['12-0-x-up-to-16-tall-gather-door-kit'],
  '36235001': values => calculateWinch(values, 'r'),
  '36240001': values => calculateWinch(values, 'l'),
  '31540000': values => (calculateWinch(values, 'r') + calculateWinch(values, 'l')) * 6 - 24,
  '27120000': values => {
    let qty = (parseInt(values.rafterSpacing) <= 10) ? calculateCableAssembly(values.buildingWidth) : 0

    qty += (parseInt(values.rafterSpacing) > 10) ? calculateCableAssembly(values.buildingWidth) : 0
    qty += 1

    return qty
  },
  '27035000': values => {
    let qty = (parseInt(values.rafterSpacing) <= 10) ? calculateCableAssembly(values.buildingWidth) : 0

    qty += (parseInt(values.rafterSpacing) > 10) ? calculateCableAssembly(values.buildingWidth) : 0
    qty *= 3
    qty += 1

    return qty
  },
  '23050000': (values, parts) => (
    parts['31105000'] +
    parts['41010016'] * 2 +
    parts['31330001'] * 2 + 2
  ),
  '23080000': values => 0,
  '23110000': (values, parts) => (
    parts['41010024'] * 2 + 2 +
    parts['31190000'] * 2
  ),
  '23105000': (values, parts) => (
    (
      parts['41010397'] +
      parts['41010413'] +
      parts['41010414'] +
      parts['41010017'] +
      parts['41010018'] +
      parts['48230000'] +
      parts['41010019'] +
      parts['41010020']
    ) * 4 +
    (
      parts['41010415'] +
      parts['41010416'] +
      parts['41010417'] +
      parts['41010021'] +
      parts['48285000'] +
      parts['48235000'] +
      parts['47005001'] +
      parts['41010022'] +
      parts['41010398'] +
      parts['41010418'] +
      parts['41010419'] +
      parts['41010155'] +
      parts['41010156'] +
      parts['41010157'] +
      parts['41010158'] +
      parts['41010159']
    ) * 2 +
    parts['41010023'] * 2 +
    parts['41010160'] * 2 +
    parts['41010025'] +
    parts['41010217'] + 2 +
    parts['41010801'] * 2 +
    parts['41011105'] * 2 +
    parts['31010543'] * 1
  ),
  '23130000': (values, parts) => (
    (
      parts['41010025'] +
      parts['41010217'] + 2
    ) +
    (
      parts['41010627'] * 4
    )
  ),
  '23120000': (values, parts) => (
    parts['31190000'] * 2 +
    parts['41010161'] * 2 +
    parts['41010166'] * 2 +
    parts['31010543'] * 1 +
    (
      (
        values.solidEndwallKit +
        values['19-7-1-2-door-opening-endwall-kit'] +
        values['16-0-door-opening-endwall-kit'] +
        values['14-0-door-opening-endwall-kit'] +
        values['12-0-door-opening-endwall-kit']
      ) > 0 ? 2 : 0
    )
  ),
  '25015000': (values, parts) => (
    parts['23050000'] +
    parts['23080000'] +
    parts['23110000'] +
    parts['23105000'] +
    parts['23130000'] +
    parts['23120000']
  ),
  '25005000': (values, parts) => (
    (
      parts['23050000'] +
      parts['23080000'] +
      parts['23110000'] +
      parts['23105000'] +
      parts['23130000'] +
      parts['23120000']
    ) * 2
  ),
  '24015000': (values, parts) => (
    parts['23050000'] +
    parts['23080000'] +
    parts['23110000'] +
    parts['23105000'] +
    parts['23130000'] +
    parts['23120000']
  ),
  '23465001': (values, parts) => (
    (values['ground-mount-cover'] ? parts['31330001'] : 0) +
    parts['41010628'] +
    parts['41010629'] +
    parts['41010630'] +
    parts['41010631']
  ),
  '25075000': (values, parts) => (
    parts['23560000'] * 2 +
    parts['23465001'] * 2
  ),
  '25090000': (values, parts) => (
    parts['23560000'] * 2 +
    parts['23465001']
  ),
  '24120000': (values, parts) => (
    parts['23560000'] * 2 +
    parts['23465001']
  ),
  '27480002': (values, parts) => Math.ceil((
    parts['41010027'] * 8 +
    parts['27180000'] * 1 +
    parts['41010166'] * 8 +
    parts['31010006'] * 8 +
    parts['41325000'] * 20 +
    parts['31040000'] * 9 +
    parts['31035000'] * 5 +
    parts['41010162'] * 8 +
    parts['41010161'] * 10 +
    parts['31190000'] * 2 +
    parts['27160000'] * 6 +
    parts['91215000'] * 36 +
    parts['36295000'] * 10
  ) * 1.05),
  '27075000': values => 8 * values['walk-door-kit'],
  '27480007': (values, parts) => {
    let qty = parts['63055012'] * 2

    if (parts['63055020']) {
      qty += ((values.numberOfMainCovers + 1) * 2)
    }

    return qty
  },
  '27480009': values => 0,
  '31595000': values => {
    let conditions
    let condition
    let qty = 0

    if (values.mainCoverTerminationStyle === 'monolithic') {
      conditions = {
        '30s': 58,
        '40s': 70,
        '50s': 86,
        '60s': 115,
        '70s': 124,
        '80s': 75,
        '30hp': 40,
        '40hp': 47,
        '50hp': 55,
        '60hp': 64
      }

      condition = conditions[values.buildingWidth]

      if (condition) {
        qty += (condition * values.numberOfMainCovers)
      }
    }

    conditions = {
      '30hp': 40,
      '40hp': 47,
      '50hp': 55,
      '60hp': 64,
      '80s': 75
    }

    condition = conditions[values.buildingWidth]

    if (condition && values.numberOfMainCovers > 2) {
      qty += ((values.numberOfMainCovers - 2) * condition)
    }

    if (values.mainCoverTerminationStyle === 'monolithic' && values.numberOfMainCovers === 1) {
      conditions = {
        '80s': 75,
        '30hp': 40,
        '40hp': 47,
        '50hp': 55,
        '60hp': 64
      }

      condition = conditions[values.buildingWidth]

      if (condition) {
        qty += condition
      }
    }

    if (values.mainCoverTerminationStyle === 'hybrid') {
      conditions = {
        '30s': 58,
        '40s': 70,
        '50s': 86,
        '60s': 115,
        '70s': 124,
        '80s': 150,
        '30hp': 80,
        '40hp': 94,
        '50hp': 110,
        '60hp': 128
      }

      condition = conditions[values.buildingWidth]

      if (condition) {
        qty += condition

        if (values.numberOfMainCovers > 2) {
          qty += (condition * (values.numberOfMainCovers - 2))
        }
      }
    }

    conditions = {
      '30s': 29,
      '40s': 35,
      '50s': 43,
      '60s': 53,
      '70s': 61,
      '80s': 73,
      '30hp': 40,
      '40hp': 45,
      '50hp': 52,
      '60hp': 57
    }

    condition = conditions[values.buildingWidth]

    if (condition) {
      qty += (condition * (
        values['solidEndwallKit'] +
        values['19-7-1-2-door-opening-endwall-kit'] +
        values['16-0-door-opening-endwall-kit'] +
        values['14-0-door-opening-endwall-kit'] +
        values['12-0-door-opening-endwall-kit']
      ))
    }

    conditions = {
      '30s': 8,
      '40s': 28,
      '50s': 32,
      '60s': 56,
      '70s': 64,
      '80s': 104,
      '30hp': 12,
      '40hp': 46,
      '50hp': 52,
      '60hp': 66
    }

    condition = conditions[values.buildingWidth]

    if (condition) {
      qty += (condition * values.solidEndwallKit)
    }

    conditions = {
      '30s': 14,
      '40s': 14,
      '50s': 14,
      '60s': 28,
      '70s': 34,
      '80s': 68,
      '30hp': 9,
      '40hp': 12,
      '50hp': 26,
      '60hp': 34
    }

    condition = conditions[values.buildingWidth]

    if (condition) {
      qty += (condition * values['19-7-1-2-door-opening-endwall-kit'])
    }

    conditions = {
      '30s': 10,
      '40s': 10,
      '50s': 10,
      '60s': 28,
      '70s': 34,
      '80s': 69,
      '30hp': 9,
      '40hp': 22,
      '50hp': 26,
      '60hp': 34
    }

    condition = conditions[values.buildingWidth]

    if (condition) {
      qty += (condition * values['16-0-door-opening-endwall-kit'])
    }

    conditions = {
      '30s': 10,
      '40s': 10,
      '50s': 10,
      '60s': 28,
      '70s': 46,
      '80s': 70,
      '30hp': 9,
      '40hp': 24,
      '50hp': 26,
      '60hp': 34
    }

    condition = conditions[values.buildingWidth]

    if (condition) {
      qty += (condition * values['14-0-door-opening-endwall-kit'])
    }

    conditions = {
      '30s': 8,
      '40s': 8,
      '50s': 28,
      '60s': 28,
      '70s': 46,
      '80s': 70,
      '30hp': 9,
      '40hp': 22,
      '50hp': 26,
      '60hp': 48
    }

    condition = conditions[values.buildingWidth]

    if (condition) {
      qty += (condition * values['12-0-door-opening-endwall-kit'])
    }

    return qty
  },
  '31580010': values => calculatePVCwCoupler120(values),
  '31585000': values => 0,
  '31010012': values => calculateRectangularPipeCap(values),
  '27050000': values => Math.ceil((values.buildingLength / 100) + getEndwallCount(values)),
  '31565000': values => 3 * getTallGatherDoorKitCount(values),
  '23240000': values => (3 * getTallGatherDoorKitCount(values)) + (getTallGatherDoorKitCount(values) > 0 ? 1 : 0),
  '25055000': values => 2 * ((3 * getTallGatherDoorKitCount(values)) + (getTallGatherDoorKitCount(values) > 0 ? 1 : 0)),
  '24060000': values => (3 * getTallGatherDoorKitCount(values)) + (getTallGatherDoorKitCount(values) > 0 ? 1 : 0),
  '23285000': values => (3 * (3 * getTallGatherDoorKitCount(values))) + (getTallGatherDoorKitCount(values) > 0 ? 1 : 0),
  '25070000': values => 2 * ((3 * (3 * getTallGatherDoorKitCount(values))) + (getTallGatherDoorKitCount(values) > 0 ? 1 : 0)),
  '24085000': values => (3 * (3 * getTallGatherDoorKitCount(values))) + (getTallGatherDoorKitCount(values) > 0 ? 1 : 0),
  '27025000': values => 6 * getTallGatherDoorKitCount(values),
  '27005000': values => 100 * getTallGatherDoorKitCount(values),
  '27180000': (values, parts) => (
    parts['41010165'] * 13 +
    parts['41010164'] * 2 +
    values.numberOfMainCovers * 4 +
    parts['36295000'] * 10 +
    values.solidEndwallKit * 2 +
    (
      values['19-7-1-2-door-opening-endwall-kit'] +
      values['16-0-door-opening-endwall-kit'] +
      values['14-0-door-opening-endwall-kit'] +
      values['12-0-door-opening-endwall-kit']
    ) * 2 +
    (
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit'] +
      values['walk-door-kit']
    ) * 2
  ),
  '27265000': values => getTallGatherDoorKitCount(values),
  '31510000': values => values['walk-door-kit'],
  '31515000': values => values['walk-door-kit'],
  '27160000': values => 0,
  '31555000': values => 1,
  '31795000': values => {
    const conditions = {
      '30s': 0,
      '40s': 0,
      '50s': 0,
      '60s': 0,
      '70s': 0,
      '80s': 75,
      '30hp': 40,
      '40hp': 47,
      '50hp': 55,
      '60hp': 64
    }

    const condition = conditions[values.buildingWidth]

    if (typeof condition !== 'undefined') {
      return getEndwallCount(values) * condition
    }
  },
  '27090000': values => Math.ceil(calculateKederTrack(values) / 40),
  '27045001': values => {
    if (
      calculateWhiteCapChannel(values) > 0 &&
      (
        values.kederCapChannel === '20' &&
        (values.mainCoverTerminationStyle === 'individual-bay-keder' || values.mainCoverTerminationStyle === 'sectional-keder')
      )
    ) {
      return Math.ceil(calculateWhiteCapChannel(values) / 5)
    }
  },
  '27200000': (values, parts) => {
    if (values['building-to-foundation-fasteners'] && values.foundationMaterial === 'concrete') {
      return (
        (
          parts['41010025'] +
          parts['41010026'] +
          parts['41010217'] +
          parts['41010218'] +
          parts['41010628'] +
          parts['41010629'] +
          parts['41010630'] +
          parts['41010631']
        ) * 2 - (parts['23560000'] * 2 / 3) + 10
      )
    }
  },
  '27465000': (values, parts) => {
    if (values['building-to-foundation-fasteners'] && values.foundationMaterial === 'concrete') {
      return (parts['36235001'] + parts['36240001'] + parts['41010162'] * 2 + 10)
    }
  },
  '27430000': (values, parts) => {
    if (values['building-to-foundation-fasteners'] && values.foundationMaterial === 'wood') {
      return (
        (
          parts['41010025'] +
          parts['41010026'] +
          parts['41010217'] +
          parts['41010218'] +
          parts['41010628'] +
          parts['41010629'] +
          parts['41010630'] +
          parts['41010631']
        ) * 2 - (parts['23560000'] * 2 / 3)
      )
    }
  },
  '27430001': (values, parts) => {
    if (values['building-to-foundation-fasteners'] && values.foundationMaterial === 'wood') {
      return (parts['36235001'] + parts['36240001'] + parts['41010162'] * 2 - (parts['23560000'] / 3))
    }
  },
  '23590000': values => {
    let qty = 4 * (values['intermediate-single-hole-winche-brackets-between-rafters'] ? ((parseInt(values.buildingLength) / parseInt(values.rafterSpacing) + 1) * 2) : 0)
    qty += 4 * getTreatedBoards(values)
    return qty
  },
  '25020000': values => {
    let qty = 4 * (values['intermediate-single-hole-winche-brackets-between-rafters'] ? ((parseInt(values.buildingLength) / parseInt(values.rafterSpacing) + 1) * 2) : 0)
    qty += 4 * getTreatedBoards(values)
    return qty
  },
  '25010000': values => {
    let qty = 4 * (values['intermediate-single-hole-winche-brackets-between-rafters'] ? ((parseInt(values.buildingLength) / parseInt(values.rafterSpacing) + 1) * 2) : 0)
    qty += 4 * getTreatedBoards(values)
    return qty
  },
  '24030000': values => {
    let qty = 4 * (values['intermediate-single-hole-winche-brackets-between-rafters'] ? ((parseInt(values.buildingLength) / parseInt(values.rafterSpacing) + 1) * 2) : 0)
    qty += 4 * getTreatedBoards(values)
    return qty
  },
  '32220000': values => values['treated-2x6-wood-rubrail'] ? calculateTreatedBoard(values, [8, 4], 8) : null,
  '32220010-1': values => values['treated-2x6-wood-rubrail'] ? calculateTreatedBoard(values, [10, 5], 10) : null,
  '32220011-1': values => values['treated-2x6-wood-rubrail'] ? calculateTreatedBoard(values, [12, 6], 12) : null,
  '32220002-1': values => values['treated-2x6-wood-rubrail'] ? calculateTreatedBoard(values, [14], 14) : null,
  '32220001-1': values => values['treated-2x6-wood-rubrail'] ? calculateTreatedBoard(values, [16], 16) : null,
  '27480001': values => values['barrier-backing-plate'] ? Math.ceil(2 * ((values.buildingLength / 5) + 1)) : null,
  '36295000': values => values.ventType === '24x30-vent-with-wood-frame-kit' ? values.ventCount : null,
  '36295001': (values, parts) => parts['36295000'],
  'MV2430': values => values.ventType === '24x30-mesh-vent' ? values.ventCount : null,
  'MV4848': values => values.ventType === '48x48-mesh-vent' ? values.ventCount : null,
  '36175000': values => values['batten-strip-and-screws'] ? Math.ceil((parseInt(values.buildingWidth) * getEndwallCount(values) + ((values.buildingLength + 2) * 2)) / 8) : null,
  '27480008': values => 8 * (values['batten-strip-and-screws'] ? Math.ceil((parseInt(values.buildingWidth) * getEndwallCount(values) + ((values.buildingLength + 2) * 2)) / 8) : 0),
  '27085001': values => 0,
  '63055007': values => 4,
  '90035000': values => 1,
  '90030000-T': values => 1,
  '90030000-N': values => 1,
  '90030000-130': values => getBuildingLayout(values, '30s', ['lc1', 'lc2']),
  '90030000-1301': values => getBuildingLayout(values, '30hp', ['lc1']),
  '90030000-1302': values => getBuildingLayout(values, '30hp', ['lc2']),
  '90030000-1401': values => getBuildingLayout(values, '40s', ['lc1']),
  '90030000-1402': values => getBuildingLayout(values, '40s', ['lc2']),
  '90030000-140HP1': values => getBuildingLayout(values, '40hp', ['lc1']),
  '90030000-140HP2': values => getBuildingLayout(values, '40hp', ['lc2']),
  '90030000-1501': values => getBuildingLayout(values, '50s', ['lc1']),
  '90030000-1502': values => getBuildingLayout(values, '50s', ['lc2']),
  '90030000-150HP1': values => getBuildingLayout(values, '50hp', ['lc1']),
  '90030000-150HP2': values => getBuildingLayout(values, '50hp', ['lc2']),
  '90030000-1601': values => getBuildingLayout(values, '60s', ['lc1']),
  '90030000-1602': values => getBuildingLayout(values, '60s', ['lc2']),
  '90030000-160HP1': values => getBuildingLayout(values, '60hp', ['lc1']),
  '90030000-160HP2': values => getBuildingLayout(values, '60hp', ['lc2']),
  '90030000-1701': values => getBuildingLayout(values, '70s', ['lc1']),
  '90030000-1702': values => getBuildingLayout(values, '70s', ['lc2']),
  '90030000-1801': values => getBuildingLayout(values, '80s', ['lc1']),
  '90030000-1802': values => getBuildingLayout(values, '80s', ['lc2']),
  '90030000-230': values => getBuildingDetails(values, ['30s']),
  '90030000-240': values => getBuildingDetails(values, ['30hp', '40s', '40hp']),
  '90030000-250': values => getBuildingDetails(values, ['50s', '50hp']),
  '90030000-26070': values => getBuildingDetails(values, ['60s', '60hp', '70s']),
  '90030000-280': values => getBuildingDetails(values, ['80s']),
  '90030000-3': values => 1,
  '90030000-4': values => !values['wide-baseplates'] ? getBuildingDetails(values, ['30s', '40s', '50s', '60s', '70s', '80s']) : 0,
  '90030000-4HP': values => !values['wide-baseplates'] ? getBuildingDetails(values, ['30hp', '40hp', '50hp', '60hp']) : 0,
  '90030000-4W': values => values['wide-baseplates'] ? getBuildingDetails(values, ['30s', '40s', '50s', '60s', '70s', '80s']) : 0,
  '90030000-4WHP': values => values['wide-baseplates'] ? getBuildingDetails(values, ['30hp', '40hp', '50hp', '60hp']) : 0,
  '90030000-CP1W': (values, parts) => {
    if (!values['ground-mount-cover'] && !parts['90030000-CP7W'] && (parts['90030000-CP5W'] || parts['90030000-CP5G'])) {
      return 1;
    }
  },
  '90030000-CP1G': (values, parts) => {
    if (values['ground-mount-cover'] && !parts['9003000-CP7G'] && !(parts['90030000-CP5W'] || parts['90030000-CP5G'])) {
      return 1;
    }
  },
  '90030000-CP1C': values => (values.numberOfMainCovers > 1 || values.mainCoverTerminationStyle === 'hybrid') ? 1 : null,
  '90030000-CP5W': values => values['ground-mount-cover'] ? null : getBuildingDetails(values, ['40hp', '50hp', '60hp']),
  '90030000-CP5G': values => values['ground-mount-cover'] ? getBuildingDetails(values, ['40hp', '50hp', '60hp']): null,
  '90030000-CP7W': values => (!values['ground-mount-cover'] && values.mainCoverTerminationStyle === 'sectional-keder') ? 1 : null,
  '9003000-CP7G': values => (values['ground-mount-cover'] && values.mainCoverTerminationStyle === 'sectional-keder') ? 1 : null,
  '90030000-CP6KW': values => (!values['wall-brackets'] && values.mainCoverTerminationStyle === 'keder') ? 1 : null,
  '9003000-CP6KG': values => (values['wall-brackets'] && values.mainCoverTerminationStyle === 'hybrid') ? 1 : null,
  '90030000-30A': values => values.solidEndwallKit ? getBuildingDetails(values, ['30s']) : null,
  '90030000-30B': values => values['19-7-1-2-door-opening-endwall-kit'] ? getBuildingDetails(values, ['30s']) : null,
  '90030000-30C': values => values['16-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['30s']) : null,
  '90030000-30D': values => values['14-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['30s']) : null,
  '90030000-30E': values => values['12-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['30s']) : null,
  '90030000-40A': values => values.solidEndwallKit ? getBuildingDetails(values, ['40s']) : null,
  '90030000-40B': values => values['19-7-1-2-door-opening-endwall-kit'] ? getBuildingDetails(values, ['40s']) : null,
  '90030000-40C': values => values['16-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['40s']) : null,
  '90030000-40D': values => values['14-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['40s']) : null,
  '90030000-40E': values => values['12-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['40s']) : null,
  '90030000-50A': values => values.solidEndwallKit ? getBuildingDetails(values, ['50s']) : null,
  '90030000-50B': values => values['19-7-1-2-door-opening-endwall-kit'] ? getBuildingDetails(values, ['50s']) : null,
  '90030000-50C': values => values['16-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['50s']) : null,
  '90030000-50D': values => values['14-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['50s']) : null,
  '90030000-50E': values => values['12-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['50s']) : null,
  '90030000-60A': values => values.solidEndwallKit ? getBuildingDetails(values, ['60s']) : null,
  '90030000-60B': values => values['19-7-1-2-door-opening-endwall-kit'] ? getBuildingDetails(values, ['60s']) : null,
  '90030000-60C': values => values['16-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['60s']) : null,
  '90030000-60D': values => values['14-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['60s']) : null,
  '90030000-60E': values => values['12-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['60s']) : null,
  '90030000-70A': values => values.solidEndwallKit ? getBuildingDetails(values, ['70s']) : null,
  '90030000-70B': values => values['19-7-1-2-door-opening-endwall-kit'] ? getBuildingDetails(values, ['70s']) : null,
  '90030000-70C': values => values['16-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['70s']) : null,
  '90030000-70D': values => values['14-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['70s']) : null,
  '90030000-70E': values => values['12-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['70s']) : null,
  '90030000-80A': values => values.solidEndwallKit ? getBuildingDetails(values, ['80s']) : null,
  '90030000-80B': values => values['19-7-1-2-door-opening-endwall-kit'] ? getBuildingDetails(values, ['80s']) : null,
  '90030000-80C': values => values['16-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['80s']) : null,
  '90030000-80D': values => values['14-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['80s']) : null,
  '90030000-80E': values => values['12-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['80s']) : null,
  '90030000-30HPA': values => values.solidEndwallKit ? getBuildingDetails(values, ['30hp']) : null,
  '90030000-30HPB': values => values['19-7-1-2-door-opening-endwall-kit'] ? getBuildingDetails(values, ['30hp']) : null,
  '90030000-30HPC': values => values['16-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['30hp']) : null,
  '90030000-30HPD': values => values['14-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['30hp']) : null,
  '90030000-30HPE': values => values['12-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['30hp']) : null,
  '90030000-40HPA': values => values.solidEndwallKit ? getBuildingDetails(values, ['40hp']) : null,
  '90030000-40HPB': values => values['19-7-1-2-door-opening-endwall-kit'] ? getBuildingDetails(values, ['40hp']) : null,
  '90030000-40HPC': values => values['16-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['40hp']) : null,
  '90030000-40HPD': values => values['14-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['40hp']) : null,
  '90030000-40HPE': values => values['12-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['40hp']) : null,
  '90030000-50HPA': values => values.solidEndwallKit ? getBuildingDetails(values, ['50hp']) : null,
  '90030000-50HPB': values => values['19-7-1-2-door-opening-endwall-kit'] ? getBuildingDetails(values, ['50hp']) : null,
  '90030000-50HPC': values => values['16-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['50hp']) : null,
  '90030000-50HPD': values => values['14-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['50hp']) : null,
  '90030000-50HPE': values => values['12-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['50hp']) : null,
  '90030000-60HPA': values => values.solidEndwallKit ? getBuildingDetails(values, ['60hp']) : null,
  '90030000-60HPB': values => values['19-7-1-2-door-opening-endwall-kit'] ? getBuildingDetails(values, ['60hp']) : null,
  '90030000-60HPC': values => values['16-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['60hp']) : null,
  '90030000-60HPD': values => values['14-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['60hp']) : null,
  '90030000-60HPE': values => values['12-0-door-opening-endwall-kit'] ? getBuildingDetails(values, ['60hp']) : null,
  '90030000-CP2W': values => (!values['ground-mount-cover'] && values.solidEndwallKit) ? 1 : null,
  '90030000-CP2G': values => (values['ground-mount-cover'] && values.solidEndwallKit) ? 1 : null,
  '90030000-CP3W': values => (!values['ground-mount-cover'] && getEndwallOpenCount(values) > 0) ? getBuildingDetails(values, ['30s', '40s', '50s', '60s', '70s', '30hp', '40hp', '50hp', '60hp']) : null,
  '90030000-CP3G': values => (values['ground-mount-cover'] && getEndwallOpenCount(values) > 0) ? getBuildingDetails(values, ['30s', '40s', '50s', '60s', '70s', '30hp', '40hp', '50hp', '60hp']) : null,
  '90030000-CP6W': values => (!values['ground-mount-cover'] && getEndwallOpenCount(values) > 0) ? getBuildingDetails(values, ['80s']) : null,
  '90030000-CP6G': values => (values['ground-mount-cover'] && getEndwallOpenCount(values) > 0) ? getBuildingDetails(values, ['80s']) : null,
  '90030000-CP4AD': values => {
    if (
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit'] +
      values['walk-door-kit']
    ) return 1;
  },
  '90030000-CP5A': values => {
    if (values.kederCapChannel === '20' && (values.mainCoverTerminationStyle === 'individual-bay-keder' || values.mainCoverTerminationStyle === 'sectional-keder')) {
      return calculateWhiteCapChannel(values) ? 1 : null
    }
  }
}

const calculateAdvantageCableAttachmentClip = (values) => {
  let qty = (parseInt(values.rafterSpacing) <= 10) ? calculateCableAssembly(values.buildingWidth) : 0

  qty += (parseInt(values.rafterSpacing) > 10) ? calculateCableAssembly(values.buildingWidth) : 0
  qty *= 2
  qty -= 4

  return qty
}

const calculateBasePlateQty = (values, highProfile, wide) => {
  var qty = 0
  const hp = values.buildingWidth.endsWith('hp')

  if ((wide && !values['wide-baseplates']) || (hp !== highProfile)) return

  if (!wide && values['wide-baseplates']) {
    qty = 2
  } else {
    qty = ((parseInt(values.buildingLength) / parseInt(values.rafterSpacing) + (wide ? 0 : 1)) * 2)
  }

  return qty
}

const calculateCableAssembly = (buildingWidth) => {
  const conditions = {
    '30s': 4,
    '40s': 6,
    '50s': 6,
    '60s': 8,
    '70s': 8,
    '80s': 20,
    '30hp': 6,
    '40hp': 6,
    '50hp': 8,
    '60hp': 8
  }

  const condition = conditions[buildingWidth]

  if (typeof condition !== 'undefined') {
    return condition
  }
}

const calculateCover = (values, partNumber) => {
  let qty = 0

  if (typeof partNumber === 'string' && partNumber.indexOf('EWC') === 0) {
    let buildCover = 'EWC'

    if (values.coverMaterial === 'enduro-loc-fr') {
      buildCover += 'FR'
    }

    buildCover += values.buildingWidth.toUpperCase()
    buildCover += values.endwallCoversColor.charAt(0).toUpperCase()
    buildCover += 'W'

    if (partNumber === buildCover) {
      qty = (
        values.solidEndwallKit +
        values['19-7-1-2-door-opening-endwall-kit'] +
        values['16-0-door-opening-endwall-kit'] +
        values['14-0-door-opening-endwall-kit'] +
        values['12-0-door-opening-endwall-kit']
      )
    }

    return qty
  }
}

const calculateCoverBelowZeroDistance = (belowZeroDistance, values) => {
  const conditions = {
    '30s': [42, 4],
    '40s': [54, 4],
    '50s': [64, 4],
    '60s': [80, 5],
    '70s': [91, 5],
    '80s': [109, 5],
    '30hp': [58, 5],
    '40hp': [69, 5],
    '50hp': [79, 5],
    '60hp': [90, 5]
  }

  const condition = conditions[values.buildingWidth]

  if (condition) {
    const num1 = condition[0]
    const num2 = condition[1]

    return Math.ceil(
      (num1 + belowZeroDistance) *
      (values.buildingLength + num2)
    )
  }
}

const calculateEndRafterBraceQty = (values, rafterSize, highProfile) => {
  if (parseInt(values.rafterSpacing) === rafterSize) {
    let conditions = {
      '30s': [3, 0],
      '40s': [9, 4],
      '50s': [11, 6]
    }

    if (highProfile) {
      conditions = {
        '60s': [12, 4],
        '70s': [12, 4],
        '80s': [14, 3],
        '30hp': [6, 2],
        '40hp': [7, 2],
        '50hp': [10, 2],
        '60hp': [10, 2]
      }
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      let qty = 2 * condition[0]

      if (values.buildingDesign === 'lc2') {
        qty -= (2 * condition[1])
      }

      qty *= (1 + ((parseInt(values.numberOfMainCovers) - 1) / 2))

      if (!highProfile && values.buildingDesign === 'lc1') {
        if (values.mainCoverTerminationStyle === 'individual-bay-keder') {
          qty += (((parseInt(values.buildingLength) / rafterSize) - 1) * condition[0])
        } else if (values.mainCoverTerminationStyle === 'sectional-keder') {
          qty += (Math.ceil((parseInt(values.buildingLength) / 64) - 1) * condition[0])
        }
      }

      return qty
    }
  }

  return 0
}

const calculateKederTrack = (values) => {
  if (values.mainCoverTerminationStyle === 'monolithic') {
    const conditions = {
      '30s': 0,
      '40s': 0,
      '50s': 0,
      '60s': 0,
      '70s': 0,
      '80s': 10,
      '40hp': 6,
      '50hp': 8,
      '60hp': 8
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return condition
    }
  }

  if (values.mainCoverTerminationStyle === 'hybrid') {
    const conditions = {
      '30s': 4,
      '40s': 5,
      '50s': 6,
      '60s': 7,
      '70s': 8,
      '80s': 10,
      '40hp': 6,
      '50hp': 8,
      '60hp': 8
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return condition
    }
  }

  if (values.mainCoverTerminationStyle === 'individual-bay-keder') {
    const conditions = {
      '30s': 1.8,
      '40s': 2.25,
      '50s': 2.7,
      '60s': 3.35,
      '70s': 3.8,
      '80s': 4.6,
      '30hp': 2.45,
      '40hp': 2.9,
      '50hp': 3.35,
      '60hp': 3.75
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return Math.ceil(((parseInt(values.buildingLength) / parseInt(values.rafterSpacing)) + 1) * condition)
    }
  }

  if (values.mainCoverTerminationStyle === 'sectional-keder') {
    const rafterConditions = {
      '4': 64,
      '5': 70,
      '6': 60,
      '8': 64,
      '10': 70,
      '12': 60,
      '14': 70,
      '16': 64,
      '18': 54,
      '20': 60
    }

    const conditions = {
      '30s': 1.8,
      '40s': 2.25,
      '50s': 2.7,
      '60s': 3.35,
      '70s': 3.8,
      '80s': 4.6,
      '30hp': 2.45,
      '40hp': 3,
      '50hp': 3.35,
      '60hp': 3.75
    }

    const rafterCondition = rafterConditions[values.rafterSpacing]
    const condition = conditions[values.buildingWidth]

    if (rafterCondition && condition) {
      if (parseInt(values.buildingLength) > rafterCondition) {
        return Math.ceil((1 + (parseInt(values.buildingLength) / rafterCondition)) * condition)
      } else {
        return Math.ceil(2 * condition)
      }
    }
  }
}

const calculateTubeRafterInsert = (values, conditions) => {
  const condition = conditions[values.buildingWidth]

  if (typeof condition !== 'undefined') {
    let math = (parseInt(values.buildingLength) / parseInt(values.rafterSpacing) + 1) * condition
    return math
  }
}

const calculatePurlinQty = (values, purlinSize) => {
  if (parseInt(values.rafterSpacing) === purlinSize) {
    const conditions = {
      '30s': [3, 0],
      '40s': [9, 4],
      '50s': [11, 6],
      '60s': [12, 4],
      '70s': [12, 4],
      '80s': [14, 3],
      '30hp': [9, 4],
      '40hp': [9, 4],
      '50hp': [12, 4],
      '60hp': [12, 4]
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      let qty = ((parseInt(values.buildingLength) / purlinSize) * condition[0])

      if (values.buildingDesign === 'lc2') {
        qty -= ((parseInt(values.buildingLength) / purlinSize) * condition[1])
      }

      return qty
    }
  }
  return 0
}

const calculatePVCwCoupler120 = (values) => {
  let nonSolidEndwalls = parseInt(values['19-7-1-2-door-opening-endwall-kit']) + parseInt(values['16-0-door-opening-endwall-kit']) + parseInt(values['14-0-door-opening-endwall-kit']) + parseInt(values['12-0-door-opening-endwall-kit'])
  let endwalls = getEndwallCount(values)

  let conditions = {
    '30s': 2,
    '40s': 3,
    '50s': 4,
    '60s': 4,
    '70s': 5,
    '80s': 6,
    '30hp': 3,
    '40hp': 4,
    '50hp': 4,
    '60hp': 5
  }

  let condition = conditions[values.buildingWidth]

  if (typeof condition !== 'undefined') {
    let qty = nonSolidEndwalls + (endwalls * condition)

    if (values.mainCoverTerminationStyle === 'monolithic') {
      conditions = {
        '30s': [5, 0],
        '40s': [6, 0],
        '50s': [7, 0],
        '60s': [8, 0],
        '70s': [9, 0],
        '80s': [6, 6],
        '30hp': [3, 4],
        '40hp': [4, 4],
        '50hp': [4, 4],
        '60hp': [5, 5]
      }

      condition = conditions[values.buildingWidth]

      if (condition) {
        qty += condition[0] * values.numberOfMainCovers

        if (values.numberOfMainCovers > 2) {
          qty += (values.numberOfMainCovers - 2) * condition[1]
        }

        if (values.numberOfMainCovers === 1) {
          qty += condition[1]
        }
      }
    }

    if (values.mainCoverTerminationStyle === 'hybrid') {
      conditions = {
        '30s': [5, 5],
        '40s': [6, 6],
        '50s': [7, 7],
        '60s': [8, 8],
        '70s': [10, 10],
        '80s': [12, 12],
        '30hp': [6, 6],
        '40hp': [8, 8],
        '50hp': [8, 8],
        '60hp': [10, 10]
      }

      condition = conditions[values.buildingWidth]

      if (condition) {
        qty += condition[0]

        if (values.numberOfMainCovers > 2) {
          qty += (values.numberOfMainCovers - 2) * condition[1]
        }
      }
    }

    return qty * 2
  }
}

const calculateQCell151 = (values) => {
  let qty = 0

  let conditions = {
    '30s': 130,
    '40s': 126,
    '50s': 157,
    '60s': 216,
    '70s': 242,
    '80s': 317,
    '30hp': 220,
    '40hp': 204,
    '50hp': 236,
    '60hp': 262
  }

  let condition = conditions[values.buildingWidth]

  if (condition && values.solidEndwallKit) {
    qty += condition
  }

  conditions = {
    '0-0': 0,
    '1-0': 12,
    '2-0': 24,
    '3-0': 36,
    '4-0': 48,
    '5-0': 60,
    '6-0': 72,
    '7-0': 84,
    '8-0': 96
  }

  condition = conditions[values.endwallSteelBelowZeroDistance]

  if (condition && !values.solidEndwallKit) {
    qty += condition
  }

  return qty
}

const calculateQCell152 = (values) => {
  let qty = 0

  let conditions = {
    '30s': 79,
    '40s': 126,
    '50s': 157,
    '60s': 216,
    '70s': 242,
    '80s': 318,
    '30hp': 146,
    '40hp': 204,
    '50hp': 236,
    '60hp': 262
  }

  let condition = conditions[values.buildingWidth]

  if (condition && values['19-7-1-2-door-opening-endwall-kit']) {
    qty += condition
  }

  conditions = {
    '0-0': 0,
    '1-0': 12,
    '2-0': 24,
    '3-0': 36,
    '4-0': 48,
    '5-0': 60,
    '6-0': 72,
    '7-0': 84,
    '8-0': 96
  }

  condition = conditions[values.endwallSteelBelowZeroDistance]

  if (condition && !values['19-7-1-2-door-opening-endwall-kit']) {
    qty += condition
  }

  return qty
}

const calculateQCell153 = (values) => {
  let qty = 0

  let conditions = {
    '30s': 99,
    '40s': 138,
    '50s': 165,
    '60s': 224,
    '70s': 248,
    '80s': 323,
    '30hp': 172,
    '40hp': 217,
    '50hp': 244,
    '60hp': 268
  }

  let condition = conditions[values.buildingWidth]

  if (condition && values['16-0-door-opening-endwall-kit']) {
    qty += condition
  }

  conditions = {
    '0-0': 0,
    '1-0': 12,
    '2-0': 24,
    '3-0': 36,
    '4-0': 48,
    '5-0': 60,
    '6-0': 72,
    '7-0': 84,
    '8-0': 96
  }

  condition = conditions[values.endwallSteelBelowZeroDistance]

  if (condition && !values['16-0-door-opening-endwall-kit']) {
    qty += condition
  }

  return qty
}

const calculateQCell154 = (values) => {
  let qty = 0

  let conditions = {
    '30s': 107,
    '40s': 143,
    '50s': 169,
    '60s': 228,
    '70s': 250,
    '80s': 325,
    '30hp': 184,
    '40hp': 222,
    '50hp': 248,
    '60hp': 271
  }

  let condition = conditions[values.buildingWidth]

  if (condition && values['14-0-door-opening-endwall-kit']) {
    qty += condition
  }

  conditions = {
    '0-0': 0,
    '1-0': 12,
    '2-0': 24,
    '3-0': 36,
    '4-0': 48,
    '5-0': 60,
    '6-0': 72,
    '7-0': 84,
    '8-0': 96
  }

  condition = conditions[values.endwallSteelBelowZeroDistance]

  if (condition && !values['14-0-door-opening-endwall-kit']) {
    qty += condition
  }

  return qty
}

const calculateQCell157 = (values) => {
  let qty = 0

  let conditions = {
    '30s': 113,
    '40s': 147,
    '50s': 172,
    '60s': 231,
    '70s': 253,
    '80s': 327,
    '30hp': 194,
    '40hp': 227,
    '50hp': 251,
    '60hp': 273
  }

  let condition = conditions[values.buildingWidth]

  if (condition && values['12-0-door-opening-endwall-kit']) {
    qty += condition
  }

  conditions = {
    '0-0': 0,
    '1-0': 12,
    '2-0': 24,
    '3-0': 36,
    '4-0': 48,
    '5-0': 60,
    '6-0': 72,
    '7-0': 84,
    '8-0': 96
  }

  condition = conditions[values.endwallSteelBelowZeroDistance]

  if (condition && !values['12-0-door-opening-endwall-kit']) {
    qty += condition
  }

  return qty
}

const calculateQCell159 = (values) => {
  let qty = 0

  const qCell151 = calculateQCell151(values)
  const qCell152 = calculateQCell152(values)
  const qCell153 = calculateQCell153(values)
  const qCell154 = calculateQCell154(values)
  const qCell157 = calculateQCell157(values)

  if (qCell151 < 240.5) {
    qty += ((2 + ((values.buildingWidthNumber === 30) ? -1 : 0)) * values.solidEndwallKit)
  }

  if (qCell152 < 240.5) {
    qty += (2 * values['19-7-1-2-door-opening-endwall-kit'])
  }

  if (qCell153 < 240.5) {
    qty += (2 * values['16-0-door-opening-endwall-kit'])
  }

  if (qCell154 < 240.5) {
    qty += (2 * values['14-0-door-opening-endwall-kit'])
  }

  if (qCell157 < 240.5) {
    qty += (2 * values['12-0-door-opening-endwall-kit'])
  }

  return qty
}

const calculateQCell160 = (values) => {
  let qty = 0

  const qCell151 = calculateQCell151(values)
  const qCell152 = calculateQCell152(values)
  const qCell153 = calculateQCell153(values)
  const qCell154 = calculateQCell154(values)
  const qCell157 = calculateQCell157(values)

  if (240.5 < qCell151 && qCell151 < 288.5) {
    qty += ((2 + ((values.buildingWidthNumber === 30) ? -1 : 0)) * values.solidEndwallKit)
  }

  if (240.5 < qCell152 && qCell152 < 288.5) {
    qty += (2 * values['19-7-1-2-door-opening-endwall-kit'])
  }

  if (240.5 < qCell153 && qCell153 < 288.5) {
    qty += (2 * values['16-0-door-opening-endwall-kit'])
  }

  if (240.5 < qCell154 && qCell154 < 288.5) {
    qty += (2 * values['14-0-door-opening-endwall-kit'])
  }

  if (240.5 < qCell157 && qCell157 < 288.5) {
    qty += (2 * values['12-0-door-opening-endwall-kit'])
  }

  return qty
}

const calculateQCell161 = (values) => {
  let qty = 0

  const qCell151 = calculateQCell151(values)
  const qCell152 = calculateQCell152(values)
  const qCell153 = calculateQCell153(values)
  const qCell154 = calculateQCell154(values)
  const qCell157 = calculateQCell157(values)

  if (288.5 < qCell151 && qCell151 < 336.5) {
    qty += ((2 + ((values.buildingWidthNumber === 30) ? -1 : 0)) * values.solidEndwallKit)
  }

  if (288.5 < qCell152 && qCell152 < 336.5) {
    qty += (2 * values['19-7-1-2-door-opening-endwall-kit'])
  }

  if (288.5 < qCell153 && qCell153 < 336.5) {
    qty += (2 * values['16-0-door-opening-endwall-kit'])
  }

  if (288.5 < qCell154 && qCell154 < 336.5) {
    qty += (2 * values['14-0-door-opening-endwall-kit'])
  }

  if (288.5 < qCell157 && qCell157 < 336.5) {
    qty += (2 * values['12-0-door-opening-endwall-kit'])
  }

  return qty
}

const calculateQCell162 = (values) => {
  let qty = 0

  const qCell151 = calculateQCell151(values)
  const qCell152 = calculateQCell152(values)
  const qCell153 = calculateQCell153(values)
  const qCell154 = calculateQCell154(values)
  const qCell157 = calculateQCell157(values)

  if (336.5 < qCell151 && qCell151 < 384.5) {
    qty += ((2 + ((values.buildingWidthNumber === 30) ? -1 : 0)) * values.solidEndwallKit)
  }

  if (336.5 < qCell152 && qCell152 < 384.5) {
    qty += (2 * values['19-7-1-2-door-opening-endwall-kit'])
  }

  if (336.5 < qCell153 && qCell153 < 384.5) {
    qty += (2 * values['16-0-door-opening-endwall-kit'])
  }

  if (336.5 < qCell154 && qCell154 < 384.5) {
    qty += (2 * values['14-0-door-opening-endwall-kit'])
  }

  if (336.5 < qCell157 && qCell157 < 384.5) {
    qty += (2 * values['12-0-door-opening-endwall-kit'])
  }

  return qty
}

const calculateQCell163 = (values) => {
  let qty = 0

  const qCell151 = calculateQCell151(values)
  const qCell152 = calculateQCell152(values)
  const qCell153 = calculateQCell153(values)
  const qCell154 = calculateQCell154(values)
  const qCell157 = calculateQCell157(values)

  if (384.5 < qCell151 && qCell151 < 432.5) {
    qty += ((2 + ((values.buildingWidthNumber === 30) ? -1 : 0)) * values.solidEndwallKit)
  }

  if (384.5 < qCell152 && qCell152 < 432.5) {
    qty += (2 * values['19-7-1-2-door-opening-endwall-kit'])
  }

  if (384.5 < qCell153 && qCell153 < 432.5) {
    qty += (2 * values['16-0-door-opening-endwall-kit'])
  }

  if (384.5 < qCell154 && qCell154 < 432.5) {
    qty += (2 * values['14-0-door-opening-endwall-kit'])
  }

  if (384.5 < qCell157 && qCell157 < 432.5) {
    qty += (2 * values['12-0-door-opening-endwall-kit'])
  }

  return qty
}

const calculateQCell166 = (values) => {
  let qty = 0

  let conditions = {
    '80s': 169
  }

  let condition = conditions[values.buildingWidth]

  if (condition && values.solidEndwallKit) {
    qty += condition
  }

  conditions = {
    '0-0': 0,
    '1-0': 12,
    '2-0': 24,
    '3-0': 36,
    '4-0': 48,
    '5-0': 60,
    '6-0': 72,
    '7-0': 84,
    '8-0': 96
  }

  condition = conditions[values.endwallSteelBelowZeroDistance]

  if (condition && !values.solidEndwallKit) {
    qty += condition
  }

  return qty
}

const calculateQCell167 = (values) => {
  let qty = 0

  let conditions = {
    '80s': 169
  }

  let condition = conditions[values.buildingWidth]

  if (condition && values['19-7-1-2-door-opening-endwall-kit']) {
    qty += condition
  }

  conditions = {
    '0-0': 0,
    '1-0': 12,
    '2-0': 24,
    '3-0': 36,
    '4-0': 48,
    '5-0': 60,
    '6-0': 72,
    '7-0': 84,
    '8-0': 96
  }

  condition = conditions[values.endwallSteelBelowZeroDistance]

  if (condition && !values['19-7-1-2-door-opening-endwall-kit']) {
    qty += condition
  }

  return qty
}

const calculateQCell168 = (values) => {
  let qty = 0

  let conditions = {
    '80s': 193
  }

  let condition = conditions[values.buildingWidth]

  if (condition && values['16-0-door-opening-endwall-kit']) {
    qty += condition
  }

  conditions = {
    '0-0': 0,
    '1-0': 12,
    '2-0': 24,
    '3-0': 36,
    '4-0': 48,
    '5-0': 60,
    '6-0': 72,
    '7-0': 84,
    '8-0': 96
  }

  condition = conditions[values.endwallSteelBelowZeroDistance]

  if (condition && !values['16-0-door-opening-endwall-kit']) {
    qty += condition
  }

  return qty
}

const calculateQCell169 = (values) => {
  let qty = 0

  let conditions = {
    '70s': 103,
    '80s': 205
  }

  let condition = conditions[values.buildingWidth]

  if (condition && values['14-0-door-opening-endwall-kit']) {
    qty += condition
  }

  conditions = {
    '0-0': 0,
    '1-0': 12,
    '2-0': 24,
    '3-0': 36,
    '4-0': 48,
    '5-0': 60,
    '6-0': 72,
    '7-0': 84,
    '8-0': 96
  }

  condition = conditions[values.endwallSteelBelowZeroDistance]

  if (condition && !values['14-0-door-opening-endwall-kit']) {
    qty += condition
  }

  return qty
}

const calculateQCell170 = (values) => {
  let qty = 0

  let conditions = {
    '70s': 111,
    '80s': 210,
    '60hp': 110
  }

  let condition = conditions[values.buildingWidth]

  if (condition && values['12-0-door-opening-endwall-kit']) {
    qty += condition
  }

  conditions = {
    '0-0': 0,
    '1-0': 12,
    '2-0': 24,
    '3-0': 36,
    '4-0': 48,
    '5-0': 60,
    '6-0': 72,
    '7-0': 84,
    '8-0': 96
  }

  condition = conditions[values.endwallSteelBelowZeroDistance]

  if (condition && !values['12-0-door-opening-endwall-kit']) {
    qty += condition
  }

  return qty
}

const calculateQCell172 = (values) => {
  let qty = 0

  const qCell166 = calculateQCell166(values)
  const qCell167 = calculateQCell167(values)
  const qCell168 = calculateQCell168(values)
  const qCell169 = calculateQCell169(values)
  const qCell170 = calculateQCell170(values)

  if (0 < qCell166 && qCell166 < 240.5) {
    qty += ((2 + ((values.buildingWidthNumber === 30) ? -1 : 0)) * values.solidEndwallKit)
  }

  if (0 < qCell167 && qCell167 < 240.5) {
    qty += (2 * values['19-7-1-2-door-opening-endwall-kit'])
  }

  if (0 < qCell168 && qCell168 < 240.5) {
    qty += (2 * values['16-0-door-opening-endwall-kit'])
  }

  if (0 < qCell169 && qCell169 < 240.5) {
    qty += (2 * values['14-0-door-opening-endwall-kit'])
  }

  if (0 < qCell170 && qCell170 < 240.5) {
    qty += (2 * values['12-0-door-opening-endwall-kit'])
  }

  return qty
}

const calculateQCell173 = (values) => {
  let qty = 0

  const qCell166 = calculateQCell166(values)
  const qCell167 = calculateQCell167(values)
  const qCell168 = calculateQCell168(values)
  const qCell169 = calculateQCell169(values)
  const qCell170 = calculateQCell170(values)

  if (240.5 < qCell166 && qCell166 < 288.5) {
    qty += ((2 + ((values.buildingWidthNumber === 30) ? -1 : 0)) * values.solidEndwallKit)
  }

  if (240.5 < qCell167 && qCell167 < 288.5) {
    qty += (2 * values['19-7-1-2-door-opening-endwall-kit'])
  }

  if (240.5 < qCell168 && qCell168 < 288.5) {
    qty += (2 * values['16-0-door-opening-endwall-kit'])
  }

  if (240.5 < qCell169 && qCell169 < 288.5) {
    qty += (2 * values['14-0-door-opening-endwall-kit'])
  }

  if (240.5 < qCell170 && qCell170 < 288.5) {
    qty += (2 * values['12-0-door-opening-endwall-kit'])
  }

  return qty
}

const calculateQCell174 = (values) => {
  let qty = 0

  const qCell166 = calculateQCell166(values)
  const qCell167 = calculateQCell167(values)
  const qCell168 = calculateQCell168(values)
  const qCell169 = calculateQCell169(values)
  const qCell170 = calculateQCell170(values)

  if (288.5 < qCell166 && qCell166 < 336.5) {
    qty += ((2 + ((values.buildingWidthNumber === 30) ? -1 : 0)) * values.solidEndwallKit)
  }

  if (288.5 < qCell167 && qCell167 < 336.5) {
    qty += (2 * values['19-7-1-2-door-opening-endwall-kit'])
  }

  if (288.5 < qCell168 && qCell168 < 336.5) {
    qty += (2 * values['16-0-door-opening-endwall-kit'])
  }

  if (288.5 < qCell169 && qCell169 < 336.5) {
    qty += (2 * values['14-0-door-opening-endwall-kit'])
  }

  if (288.5 < qCell170 && qCell170 < 336.5) {
    qty += (2 * values['12-0-door-opening-endwall-kit'])
  }

  return qty
}

const calculateQCell175 = (values) => {
  let qty = 0

  const qCell166 = calculateQCell166(values)
  const qCell167 = calculateQCell167(values)
  const qCell168 = calculateQCell168(values)
  const qCell169 = calculateQCell169(values)
  const qCell170 = calculateQCell170(values)

  if (336.5 < qCell166 && qCell166 < 384.5) {
    qty += ((2 + ((values.buildingWidthNumber === 30) ? -1 : 0)) * values.solidEndwallKit)
  }

  if (336.5 < qCell167 && qCell167 < 384.5) {
    qty += (2 * values['19-7-1-2-door-opening-endwall-kit'])
  }

  if (336.5 < qCell168 && qCell168 < 384.5) {
    qty += (2 * values['16-0-door-opening-endwall-kit'])
  }

  if (336.5 < qCell169 && qCell169 < 384.5) {
    qty += (2 * values['14-0-door-opening-endwall-kit'])
  }

  if (336.5 < qCell170 && qCell170 < 384.5) {
    qty += (2 * values['12-0-door-opening-endwall-kit'])
  }

  return qty
}

const calculateQCell176 = (values) => {
  let qty = 0

  const qCell166 = calculateQCell166(values)
  const qCell167 = calculateQCell167(values)
  const qCell168 = calculateQCell168(values)
  const qCell169 = calculateQCell169(values)
  const qCell170 = calculateQCell170(values)

  if (384.5 < qCell166 && qCell166 < 432.5) {
    qty += ((2 + ((values.buildingWidthNumber === 30) ? -1 : 0)) * values.solidEndwallKit)
  }

  if (384.5 < qCell167 && qCell167 < 432.5) {
    qty += (2 * values['19-7-1-2-door-opening-endwall-kit'])
  }

  if (384.5 < qCell168 && qCell168 < 432.5) {
    qty += (2 * values['16-0-door-opening-endwall-kit'])
  }

  if (384.5 < qCell169 && qCell169 < 432.5) {
    qty += (2 * values['14-0-door-opening-endwall-kit'])
  }

  if (384.5 < qCell170 && qCell170 < 432.5) {
    qty += (2 * values['12-0-door-opening-endwall-kit'])
  }

  return qty
}

const calculateRectangularPipeCap = (values) => {
  let endwalls = getEndwallCount(values)
  let qty = (endwalls * 2) + (4 * values.numberOfMainCovers)

  if (values.mainCoverTerminationStyle === 'sectional-keder') {
    qty += 4 * Math.ceil((values.buildingLength/64)-1)
  }

  if (values.mainCoverTerminationStyle === 'hybrid') {
    qty += 4
  }

  if (values.mainCoverTerminationStyle === 'monolithic') {
    if (values.buildingWidth === '80s' || values.buildingWidth === '40hp' || values.buildingWidth === '50hp' || values.buildingWidth === '60hp') {
      qty += 4
    }
  }

  return qty
}

const calculateSquareTube = (values, length) => {
  let conditions = {}
  let condition
  let qty = 0

  const doorKits = (
    values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
    values['16-0-x-up-to-16-tall-gather-door-kit'] +
    values['14-0-x-up-to-16-tall-gather-door-kit'] +
    values['12-0-x-up-to-16-tall-gather-door-kit']
  )

  if (length === 20) {
    conditions = {
      '30s':  [0, 1, 1, 1, 1],
      '40s':  [1, 1, 1, 1, 1],
      '50s':  [1, 1, 1, 1, 1],
      '60s':  [1, 1, 3, 3, 3],
      '70s':  [3, 3, 3, 3, 3],
      '80s':  [4, 3, 3, 3, 3],
      '30hp': [0, 1, 1, 1, 1],
      '40hp': [2, 1, 2, 2, 2],
      '50hp': [1, 1, 3, 3, 3],
      '60hp': [3, 3, 3, 3, 3]
    }
  } else if (length === 24) {
    conditions = {
      '30s':  [0, 0, 0, 0, 0],
      '40s':  [0, 0, 0, 0, 0],
      '50s':  [0, 0, 0, 0, 0],
      '60s':  [1, 1, 0, 0, 0],
      '70s':  [0, 0, 0, 0, 0],
      '80s':  [0, 0, 0, 0, 0],
      '30hp': [0, 0, 0, 0, 0],
      '40hp': [0, 0, 0, 0, 0],
      '50hp': [1, 1, 0, 0, 0],
      '60hp': [0, 0, 0, 0, 0]
    }
  }

  condition = conditions[values.buildingWidth]

  if (condition) {
    qty = (
      condition[0] *
      values.solidEndwallKit +
      condition[1] *
      values['19-7-1-2-door-opening-endwall-kit'] +
      condition[2] *
      values['16-0-door-opening-endwall-kit'] +
      condition[3] *
      values['14-0-door-opening-endwall-kit'] +
      condition[4] *
      values['12-0-door-opening-endwall-kit']
    )
  }

  if (length === 20) {
    qty += (doorKits + calculateQCell159(values) + calculateQCell172(values))
  } else if (length === 24) {
    qty += (doorKits + calculateQCell160(values) + calculateQCell173(values))
  }

  conditions = {}

  if (length === 20) {
    conditions = {
      '30s':  [2, 1, 1, 1, 1],
      '40s':  [2, 1, 0, 0, 0],
      '50s':  [3, 2, 2, 2, 2],
      '60s':  [3, 2, 2, 0, 0],
      '70s':  [1, 0, 0, 3, 3],
      '80s':  [4, 3, 3, 2, 2],
      '30hp': [2, 1, 1, 1, 1],
      '40hp': [2, 1, 0, 0, 2],
      '50hp': [3, 2, 2, 2, 2],
      '60hp': [3, 2, 0, 0, 3]
    }
  } else if (length === 24) {
    conditions = {
      '30s':  [0, 0, 0, 0, 0],
      '40s':  [0, 0, 0, 1, 1],
      '50s':  [0, 0, 0, 0, 0],
      '60s':  [0, 0, 0, 2, 2],
      '70s':  [2, 2, 2, 0, 0],
      '80s':  [0, 0, 0, 1, 1],
      '30hp': [0, 0, 0, 0, 0],
      '40hp': [0, 0, 1, 1, 0],
      '50hp': [0, 0, 0, 0, 0],
      '60hp': [0, 0, 2, 2, 0]
    }
  }

  condition = conditions[values.buildingWidth]

  if (condition && values.endwallSteelBelowZeroDistance !== '0-0') {
    qty += ((
      condition[0] *
      values.solidEndwallKit +
      condition[1] *
      values['19-7-1-2-door-opening-endwall-kit'] +
      condition[2] *
      values['16-0-door-opening-endwall-kit'] +
      condition[3] *
      values['14-0-door-opening-endwall-kit'] +
      condition[4] *
      values['12-0-door-opening-endwall-kit']
    ))
  }

  return qty
}

const calculateTreatedBoard = (values, spacing, boardSize) => {
  if (spacing.includes(parseInt(values.rafterSpacing))) {
    return Math.ceil((values.buildingLength / boardSize) * 2)
  }

  return 0
}

const calculateSwagedPullPipe = (values) => {
  const conditions = {
    '30s': [2, 1, 1, 1, 1],
    '40s': [2, 1, 1, 2, 2],
    '50s': [3, 2, 2, 2, 2],
    '60s': [3, 2, 2, 2, 2],
    '70s': [3, 2, 2, 3, 3],
    '80s': [4, 3, 3, 3, 3],
    '30hp': [2, 1, 1, 1, 1],
    '40hp': [2, 1, 1, 2, 2],
    '50hp': [3, 2, 2, 2, 2],
    '60hp': [3, 2, 2, 2, 2]
  }

  const condition = conditions[values.buildingWidth]
  let qty = Math.ceil(((parseFloat(values.buildingLength) + 2) * 2) / 24)

  if (condition) {
    if (values.solidEndwallKit) {
      qty += condition[0] * values.solidEndwallKit
    }

    if (values['19-7-1-2-door-opening-endwall-kit']) {
      qty += condition[1] * values['19-7-1-2-door-opening-endwall-kit']
    }

    if (values['16-0-door-opening-endwall-kit']) {
      qty += condition[2] * values['16-0-door-opening-endwall-kit']
    }

    if (values['14-0-door-opening-endwall-kit']) {
      qty += condition[3] * values['14-0-door-opening-endwall-kit']
    }

    if (values['12-0-door-opening-endwall-kit']) {
      qty += condition[4] * values['12-0-door-opening-endwall-kit']
    }
  }

  return qty
}

const calculateWhiteCapChannel = (values) => {
  if (values.mainCoverTerminationStyle === 'individual-bay-keder') {
    const conditions = {
      '30s': 43,
      '40s': 54,
      '50s': 64,
      '60s': 80,
      '70s': 91,
      '80s': 109,
      '30hp': 58,
      '40hp': 69,
      '50hp': 80,
      '60hp': 90
    }

    const condition = conditions[values.buildingWidth]

    if (typeof condition !== 'undefined') {
      if (values.kederCapChannel === 'continuous') {
        return Math.ceil((Math.ceil((values.buildingLength / values.rafterSpacing) - 1) * (condition + 5)))
      }
      
      return Math.ceil((Math.ceil((values.buildingLength / values.rafterSpacing) - 1) * (condition)) / 19.5)
    }
  }

  if (values.mainCoverTerminationStyle === 'sectional-keder') {
    let rafterSpacingCalc = 2

    const rafterConditions = {
      '4': 64,
      '5': 70,
      '6': 60,
      '8': 64,
      '10': 70,
      '12': 60,
      '14': 70,
      '16': 64,
      '18': 54,
      '20': 60,
    }

    const conditions = {
      '30s': 43,
      '40s': 54,
      '50s': 64,
      '60s': 80,
      '70s': 91,
      '80s': 109,
      '30hp': 58,
      '40hp': 69,
      '50hp': 80,
      '60hp': 90
    }

    const rafterCondition = rafterConditions[values.rafterSpacing]
    const condition = conditions[values.buildingWidth]

    if (typeof rafterCondition !== 'undefined' && typeof condition !== 'undefined') {
      if (values.kederCapChannel === 'continuous') {
        if (values.buildingLength > rafterCondition) {
          rafterSpacingCalc = (1 + (values.buildingLength / rafterCondition)) - 2
        } else {
          rafterSpacingCalc = 2
        }

        return Math.ceil(rafterSpacingCalc * (condition + 5))
      }

      if (values.buildingLength > rafterCondition) {
        rafterSpacingCalc = (1 + (values.buildingLength / rafterCondition)) - 2
      }

      return Math.ceil(rafterSpacingCalc * (condition / 19.5))
    }
  }
}

const calculateWinch = (values, hand) => {
  let qty = 0

  const conditions = {
    '30s':  [3, 4, 4, 4, 4],
    '40s':  [5, 4, 4, 4, 4],
    '50s':  [5, 4, 4, 4, 4],
    '60s':  [7, 6, 6, 6, 6],
    '70s':  [7, 6, 6, 8, 8],
    '80s':  [9, 8, 8, 8, 8],
    '30hp': [3, 4, 4, 4, 4],
    '40hp': [5, 4, 4, 4, 4],
    '50hp': [7, 4, 6, 6, 6],
    '60hp': [7, 6, 6, 6, 8]
  }

  const condition = conditions[values.buildingWidth]

  if (values.numberOfMainCovers > 0) {
    qty = values.buildingLength / parseInt(values.rafterSpacing)

    if (values.mainCoverTerminationStyle === 'individual-bay-keder') {
      qty *= 2
    }

    qty += 3

    if (values.mainCoverTerminationStyle === 'individual-bay-keder') {
      qty -= 1
    }
  }

  if (values['extra-intermediate-single-hole-winches-between-rafters']) {
    qty += values.buildingLength / parseInt(values.rafterSpacing)
  }

  if (condition && hand === 'r') {
    if (values.solidEndwallKit) {
      qty += condition[0] * values.solidEndwallKit
    }

    if (values['19-7-1-2-door-opening-endwall-kit']) {
      qty += condition[1] * values['19-7-1-2-door-opening-endwall-kit']
    }

    if (values['16-0-door-opening-endwall-kit']) {
      qty += condition[2] * values['16-0-door-opening-endwall-kit']
    }

    if (values['14-0-door-opening-endwall-kit']) {
      qty += condition[3] * values['14-0-door-opening-endwall-kit']
    }

    if (values['12-0-door-opening-endwall-kit']) {
      qty += condition[4] * values['12-0-door-opening-endwall-kit']
    }

    qty += values['walk-door-kit'] * 2
  }

  return qty
}

const getBuildingDetails = (values, buildingWidths) => {
  if (buildingWidths.includes(values.buildingWidth)) {
    return 1
  }
}

const getBuildingLayout = (values, buildingWidth, buildingDesign) => {
  if (values.buildingWidth === buildingWidth && buildingDesign.includes(values.buildingDesign)) {
    return 1
  }
}

const getEndwallCount = (values) => {
  return (
    parseInt(values.solidEndwallKit) +
    parseInt(values['19-7-1-2-door-opening-endwall-kit']) +
    parseInt(values['16-0-door-opening-endwall-kit']) +
    parseInt(values['14-0-door-opening-endwall-kit']) +
    parseInt(values['12-0-door-opening-endwall-kit'])
  )
}

const getEndwallOpenCount = (values) => {
  return (
    parseInt(values['19-7-1-2-door-opening-endwall-kit']) +
    parseInt(values['16-0-door-opening-endwall-kit']) +
    parseInt(values['14-0-door-opening-endwall-kit']) +
    parseInt(values['12-0-door-opening-endwall-kit'])
  )
}

const getTallGatherDoorKitCount = (values) => {
  return (
    parseInt(values['12-0-x-up-to-16-tall-gather-door-kit']) +
    parseInt(values['14-0-x-up-to-16-tall-gather-door-kit']) +
    parseInt(values['16-0-x-up-to-16-tall-gather-door-kit']) +
    parseInt(values['19-7-1-2-x-up-to-20-tall-gather-door-kit'])
  )
}

const getTreatedBoards = (values) => {
  let boards = 0

  if (values['treated-2x6-wood-rubrail']) {
    boards += calculateTreatedBoard(values, [8, 4], 8)
    boards += calculateTreatedBoard(values, [10, 5], 10)
    boards += calculateTreatedBoard(values, [12, 6], 12)
    boards += calculateTreatedBoard(values, [14], 14)
    boards += calculateTreatedBoard(values, [16], 16)
  }

  return boards
}