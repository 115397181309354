import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Select } from 'antd'

export default function UserSelect (props) {
  const [data, setData] = useState([])

  const fetchData = () => {
    axios.get(`/api/users`, {
      withCredentials: true
    }).then(res => {
      setData(res.data)
    }).catch(err => {
      console.error(err)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Select
      showSearch
      placeholder="Select a person"
      optionFilterProp="children"
      filterOption={ (input, option) => {
        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }}
      options={
        data
          .sort((a, b) => {
            const aCompare = (a.first_name || a.last_name) ? (a.last_name + ' ' + a.first_name) : a.username
            const bCompare = (b.first_name || b.last_name) ? (b.last_name + ' ' + b.first_name) : b.username
            return aCompare.localeCompare(bCompare)
          })
          .map(user => Object({
            label: (user.first_name || user.last_name) ? (user.first_name + ' ' + user.last_name) : user.username,
            value: user.id
          }))
      }
      { ...props }
    />
  )
}