import React, { useState, useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import axios from 'axios'
import {
  Row,
  Table,
  PageHeader
} from 'antd'

import EditUser from './edit.js'
import InviteUser from './invite.js'
import UpdatePassword from './password.js'

const UsersTable = function () {
  const [data, setData] = useState()
  const navigate = useNavigate()
  const sorter = (column, a, b) => (isNaN(a[column]) && isNaN(b[column]) ? (a[column] ?? '').localeCompare(b[column] ?? '') : a[column] - b[column])

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => sorter('first_name', a, b),
        multiple: 1
      }
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => sorter('last_name', a, b),
        multiple: 2
      }
    },
    {
      title: 'Username',
      dataIndex: 'username',
      sorter: {
        compare: (a, b) => sorter('username', a, b),
        multiple: 3
      }
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      sorter: {
        compare: (a, b) => sorter('email', a, b),
        multiple: 4
      }
    }
  ]

  const fetchData = () => {
    axios.get('/api/users', {
      withCredentials: true
    })
    .then(res => {
      let users = res.data

      users.map(user => {
        user.key = user.id
        return user
      })

      setData(users)
    })
    .catch((err) => {
      console.error('Somethings not right...', err)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div id="manage-users">
      <PageHeader className="manage-users-page-header" title="Manage Users" />

      <Row>
        <InviteUser />
      </Row>

      <br />

      <Table
        columns={ columns }
        dataSource={ data }
        pagination={{ defaultPageSize: 20, showSizeChanger: true }}
        onRow={ record => {
          return {
            onClick: e => navigate(`/users/${record.id}`),
            style: {
              cursor: 'pointer'
            }
          }
        }}
        size="small"
      />
    </div>
  )
}

export default function ViewUsers () {
  return (
    <div id="create-estimate">
      <Routes>
        <Route path='/*' element={ <UsersTable /> }></Route>
        <Route path='/:userId' element={ <EditUser /> }></Route>
        <Route path='/:userId/password' element={ <UpdatePassword /> }></Route>
      </Routes>
    </div>
  )
}
