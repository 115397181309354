import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import {
  Button,
  message,
  Form,
  Input,
  PageHeader,
} from 'antd'

export default function UpdatePassword () {
  const { userId } = useParams()
  const [ form ] = Form.useForm()
  const navigate = useNavigate()

  const saveChanges = () => {
    const userData = {
      password: form.getFieldValue('password')
    }

    axios.post(`/api/user/${userId}/update-password`, {
      ...userData
    }, {
      withCredentials: true
    }).then((res) => {
      if (res.data.success) {
        form.resetFields()
        message.success('Password successfully updated.')
      } else {
        message.error('There was an issue with your request.')
      }
    }, () => {
      message.error('There was an issue with your request.')
    })
  }

  return (
    <div id="new-password">
      <PageHeader className="set-new-password" title="Update User Password" onBack={ () => navigate(`/users/${userId}`) } />

      <Form form={ form } layout="vertical" name="editPassword" onFinish={ saveChanges }>

        <Form.Item
        name="password"
        label="Enter New Password"
        rules={[
          {
            required: true,
            message: 'New password is required.',
          }
        ]}
        >
          <Input.Password style={{ maxWidth: 350 }} />
        </Form.Item>

        <Form.Item
        name="password2"
        label="Confirm New Password"
        dependencies={['password']}
        rules={[
          { required: true, message: 'Please confirm your password.' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }

              return Promise.reject(new Error('The two passwords that you entered do not match!'))
            },
          })
        ]}
        >
          <Input.Password style={{ maxWidth: 350 }} />
        </Form.Item>

        <br />

        <Button type="primary" htmlType="submit">Save New Password</Button>
        <Button style={{ marginLeft: '16px' }} onClick={ () => navigate(`/users/${userId}`) }>Cancel</Button>
      </Form>
    </div>
  )
}
