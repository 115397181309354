import React, { useEffect } from 'react'
import axios from 'axios'

export default function Logout () {
  const logout = () => {
    axios.delete('/api/logout', {
      withCredentials: true
    }).then((res) => {
      window.location.href = '/'
    })
  }
  
  useEffect(() => {
    logout()
  }, [])

  return (
    <></>
  )
}