import React, { createContext, useEffect, useState } from 'react'
import axios from 'axios'

export const userContext = createContext({})

export default function UserContextProvider(props) {
  const [user, setUser] = useState({ loggedIn: false })

  useEffect(() => {
    axios.get('/api/user', {
      withCredentials: true
    }).then((res) => {
      if (res.data) {
        setUser(Object.assign({}, res.data, { loggedIn: true }))
      } else {
        setUser({ loggedIn: false })
      }
    })
  }, [])

  return (
    <userContext.Provider value={user}>{props.children}</userContext.Provider>
  )
}