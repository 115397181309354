import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { Alert, Form, Input, Button } from 'antd'

export default function PasswordReset () {
  const [ loading, setLoading ] = useState(true)
  const [ successMsg, setSuccessMsg ] = useState('')
  const [ errMsg, setErrMsg ] = useState('')
  const [ isValidKey, setIsValidKey ] = useState(false)
  const { userKey, resetKey } = useParams()

  const resetPassword = (data) => {
    setLoading(true)
    setErrMsg('')
    setSuccessMsg('')

    axios.post('/api/reset-password', {
      password: data.password,
      userKey,
      resetKey
    }, {
      withCredentials: true
    }).then((res) => {
      if (res.data.success) {
        setSuccessMsg('Your password was successfully reset. You may now log in.')
      }

      setLoading(false)
    }, () => {
      setErrMsg('An unknown error occured while attempting to reset your password.')
      setLoading(false)
    })
  }

  const verifyPasswordResetKey = () => {
    setLoading(true)

    axios.post('/api/verify-password-reset-key', {
      userKey,
      resetKey
    }, {
      withCredentials: true
    }).then((res) => {
      if (res.data.success) {
        setIsValidKey(true)
      }

      setLoading(false)
    }, () => {
      setErrMsg('Invalid password reset link.')
      setLoading(false)
    })
  }

  useEffect(() => {
    verifyPasswordResetKey()
  }, [])

  return ( (loading) ? <p>Loading...</p> :
    <>
      <div id="password-reset" className="center-page-block">
        <img src="/logo.png" className="logo" alt="AccuSteel logo" />

        <h1>Password Reset</h1>

        { successMsg && <Alert message={ successMsg } type="success" showIcon /> }
        { errMsg && <Alert message={ errMsg } type="error" showIcon /> }

        { isValidKey &&
          <Form
            name="basic"
            onFinish={ resetPassword }
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please enter your password.' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirm_password"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please confirm your password.' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }

                    return Promise.reject(new Error('The two passwords that you entered do not match!'))
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit">Reset Password</Button>
            </Form.Item>
          </Form>
        }

        <div style={{ textAlign: 'center' }}>
          <Link to="/login">Go back to login</Link>
        </div>
      </div>
    </>
  )
}