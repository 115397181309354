import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import UserContextProvider from './context/User.js'

import axios from 'axios'

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:4000'
}

ReactDOM.render(
  //<React.StrictMode>
    <UserContextProvider>
      <App />
    </UserContextProvider>,
  //</React.StrictMode>,
  document.getElementById('root')
)
