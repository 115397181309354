import React, { useContext } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Alert } from 'antd'

import Dashboard from './pages/Dashboard.js'
import Login from './pages/Login.js'
import Logout from './pages/Logout.js'
import Register from './pages/Register.js'
import PasswordReset from './pages/PasswordReset.js'
import PasswordResetRequest from './pages/PasswordResetRequest.js'

import ScrollToTop from './components/ScrollToTop.js'

import { userContext } from './context/User.js'

import './styles/main.scss'

const isProduction = process.env.REACT_APP_ENV === 'production'

function App () {
  const user = useContext(userContext)

  return (
    <>
      { !isProduction && <Alert message="TEST ENVIRONMENT" type="warning" style={{ fontWeight: 'bold', textAlign: 'center' }} /> }
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {
            user.loggedIn ? (
              <>
                <Route path='*' element={<Dashboard/>}></Route>
                <Route path='/logout' element={<Logout/>}></Route>
              </>
            ) : (
              <>
                <Route path='*' element={<Login/>}></Route>
                <Route path='/register' element={<Register/>}></Route>
                <Route path='/reset-password/:userKey/:resetKey' element={<PasswordReset/>}></Route>
                <Route path='/reset-password' element={<PasswordResetRequest/>}></Route>
              </>
            )
          }
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
